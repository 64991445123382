<template>
  <div>
    <notification-bar />
    <div>
      <transition name="payoutRequest">
        <div v-if="payoutRequest" class="dark:bg-white">
          <div class="overlay">
            <div
              class="payoutRequest my-10 dark:bg-gray-800 bg-white mx-10"
              v-click-outside="
                () => {
                  payoutRequest = false;
                }
              "
            >
              <h2 class="text-lg font-black">
                Enter the Amount you would like to request
              </h2>
              <input
                type="number"
                class="
                  px-3
                  py-2
                  mt-5
                  border-2 border-pink-300
                  rounded-lg
                  form__input
                  dark:bg-gray-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-pink-400
                  focus:border-transparent
                  dark:text-gray-200
                "
                v-model="amount"
                placeholder="Enter the requested amount"
              /><br />
              <button
                @click="sendPayout"
                class="mt-3 btn"
                :class="{ loading: loading }"
              >
                Submit Request
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="flex flex-col lg:mx-20">
      <div class="text-left mb-10 mt-3 mx-2 dark:text-gray-200">
        <h2 class="text-4xl font-black font-sans">Affilates Activity</h2>
        <p>
          Here you can view your overall affialtes activities registrations and
          purchases.
        </p>
      </div>
      <div class="-my-2 mx-2 overflow-x-auto sm:-mx-6 lg:-mx-9">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            v-if="ikmAffilates.length"
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <div class="flex flex-row mb-12 my-2 mt-3">
              <div class="bordered w-1/4 h-20 shadow-lg bg-gray-100 mx-2">
                <h1 class="text-3xl font-black font-sans">
                  {{ affilateActivity.actToday }}
                </h1>
                <p class="pt-1">Activity Today</p>
              </div>
              <div class="bordered w-1/4 h-20 shadow-lg bg-gray-100 mx-2">
                <h1 class="text-3xl font-black font-sans">
                  {{ affilateActivity.actMonth }}
                </h1>
                <p class="pt-1">This month</p>
              </div>
              <div class="bordered w-1/4 h-20 shadow-lg bg-gray-100 mx-2">
                <h1 class="text-3xl font-black font-sans">
                  {{ affilateActivity.actTotal }}
                </h1>
                <p class="pt-1">Total</p>
              </div>
            </div>
            <table class="w-full divide-y divide-gray-200">
              <thead class="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th
                    v-for="(hd, idx) in headers"
                    :key="idx"
                    scope="col"
                    class="
                      dark:text-gray-200
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                    v-text="hd.text"
                  ></th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200">
                <tr
                  v-for="(aff, idx) in ikmAffilates"
                  :key="idx"
                  class="dark:text-white"
                >
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ aff.fullName }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ aff.affilateId }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ parseDate(aff.createdAt) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ aff.creditPercent }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <button
                      class="bg-pink-100 hover:bg-pink-200 rounded-full"
                      @click="copyLink(aff)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 m-2 text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                        />
                      </svg>
                    </button>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <a
                      target="_blank"
                      :href="`/aff-activity/${aff.affilateId}`"
                      class="bg-pink-100 hover:bg-pink-200 py-2 px-5"
                    >
                      View Activity
                    </a>
                  </td>
                  <td>
                    <button
                      class="btn bg-pink-500 mt-2 text-white hover:bg-pink-700"
                      @click="requestPayout(aff)"
                    >
                      Request Payout
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <h2 class="px-3 text-xl text-left dark:text-gray-200">
              There are no activities yet
            </h2>
            {{ affilates }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationBar from "../../components/NotificationBar.vue";
import { IKM_AFFILATES } from "../../queries/chat";
import { AFF_ACT, REQUEST_POUT } from "../../queries/matches";
import { parseDate } from "../../utils/helpers";

export default {
  components: {
    NotificationBar,
  },
  apollo: {
    ikmAffilates: {
      query: IKM_AFFILATES,
    },
    affilateActivity: {
      query: AFF_ACT,
      result({ data }) {
        const afLink =
          "http://flirttool.com/register?af=" +
          data.affilateActivity.affilate.affilateId;
        this.affilateLink = afLink;
      },
    },
  },
  created() {
    this.$apollo.queries.affilateActivity.refetch();
  },
  data: () => ({
    afId: "",
    affilateLink: "asdf",
    amount: null,
    loading: false,
    affilates: null,
    headers: [
      { text: "Full Name" },
      { text: "Affilate Id" },
      { text: "Created Date" },
      { text: "Credit Percent" },
      { text: "Promo Link" },
      { text: "Actions" },
    ],
    affilateActivity: [],
    payoutRequest: false,
  }),
  methods: {
    parseDate,
    async copyLink(item) {
      const link = "https://flirttool.com/register?af=" + item.affilateId;
      await navigator.clipboard.writeText(link);
      this.$notify(
        {
          group: "top",
          title: "Link Copied",
          text: "Link Copied Successfully",
          type: "info", // info, warning
        },
        5000
      );
    },
    async copyRefLink() {
      await navigator.clipboard.writeText(this.affilateLink);
      this.$notify(
        {
          group: "top",
          title: "Link Copied",
          text: "Link Copied Successfully",
          type: "info", // info, warning
        },
        5000
      );
    },
    sendPayout() {
      this.loading = true;
      if (!this.amount) {
        this.$notify(
          {
            group: "top",
            title: "Amount is not valid",
            text: "Notification Message",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      if (this.amount > 0) {
        this.$apollo
          .mutate({
            mutation: REQUEST_POUT,
            variables: {
              amount: parseFloat(this.amount) ?? 0,
              afId: this.afId
            },
          })
          .then(({ data }) => {
            if (data) {
              this.payoutRequest = false;
              this.$notify(
                {
                  group: "top",
                  title: "Payout request is sent successfully",
                  text: "Notification Message",
                  type: "info", // info, warning
                },
                5000
              );
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        alert("payout must be greater than 0");
      }
    },
    requestPayout(item) {
      this.payoutRequest = true;
      this.afId = item.affilateId
    },
  },
};
</script>
<style scoped>
.payoutRequest {
  width: 500px;
  margin: 0px auto;
  padding: 20px;
  /* background-color: #fff; */
  border-radius: 2px;
  box-shadow: 0 2px 8px 3px;
  transition: all 0.2s ease-in;
  font-family: Helvetica, Arial, sans-serif;
}

.fadeIn-enter {
  opacity: 0;
}

.fadeIn-leave-active {
  opacity: 0;
  transition: all 0.2s step-end;
}

.fadeIn-enter .payoutRequest,
.fadeIn-leave-active.modal {
  transform: scale(1.1);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  transition: opacity 0.2s ease;
}
</style>