import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { setContext } from 'apollo-link-context'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import { split } from 'apollo-boost'
import { getMainDefinition } from 'apollo-utilities'
import { WebSocketLink } from 'apollo-link-ws'
import store from './store'
import { SubscriptionClient } from 'subscriptions-transport-ws'
// Install the vue plugin
Vue.use(VueApollo)
export const production = true;
const host = window.location.hostname;
let serverUrl = 'flirttool.com'
// Name of the localStorage item
const AUTH_TOKEN = 'Bearer'

// Http endpoint
const httpEndpoint = production ? `https://${host}/graphql` : `https://${serverUrl}/graphql`
// const httpEndpoint = production ? `https://globalchat.us/graphql` : `https://globalchat.us/graphql`

// const httpEndpoint = production ? 'https://flirttool.com/graphql' : 'http://127.0.0.1:8080/graphql' 


// Config

const authLink = setContext(async(_, { headers }) => {
    // Use your async token function here:
    const token = store.state.core.flirtok ? `Bearer ${JSON.parse(localStorage.getItem('vuex')).core.flirtok}` : ''
        // Return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token || ''
        }
    }
})

const wsClient = new SubscriptionClient(production ? `wss://${host}/subscriptions` : 'wss://' + serverUrl + '/subscriptions', {
    reconnect: true,
    connectionParams: () => {
        return { Authorization: store.state.core.flirtok ? `Bearer ${JSON.parse(localStorage.getItem('vuex')).core.flirtok}` : '' };
    },
})

const wsLink = new WebSocketLink(wsClient);

const link = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind == "OperationDefinition" &&
            definition.operation == "subscription"
    },
    wsLink,
    authLink
);


const cmsAuthLink = setContext(async(_, { headers }) => {
    // Use your async token function here:
    const token = "Bearer o3a3lPOlIeRcr1QXlhgHTx5VgVax3pVsxg5_mKbr7S4"
        // Return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token
        }
    }
})
const cmsLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind == "OperationDefinition" &&
            definition.operation == "subscription"
    },
    // wsLink,
    cmsAuthLink
);


const cmsOptions = {
    httpEndpoint,
    tokenName: AUTH_TOKEN,
    persisting: false,
    websocketsOnly: false,
    ssr: false,
    link: cmsLink,

}
const defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    // wsEndpoint: production ? 'wss://testflirttool.com/subscriptions' : 'ws://' + window.location.hostname + ':4000/subscriptions',
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    // link: myLink
    link: link,

    // Override default cache
    // cache: myCache

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => { }

    // Additional ApolloClient options
    // apollo: { ... }

    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }
}


const clientAOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint: httpEndpoint,
}

const clientBOptions = {
    httpEndpoint: "https://graphql.contentful.com/content/v1/spaces/ywkap6v1sr03"
        // httpEndpoint: 'https://graphql.contentful.com/content/v1/spaces/ywkap6v1sr03/explore?access_token=0QFKriEFCKVwegEZMidnRX8ZGqNJLnK6c0kXTQRzf-A',
}


// Create apollo client
export const flirttool = createApolloClient({
        ...defaultOptions,
        ...clientAOptions,
        // ...options
    })
    // apolloClient.wsClient = wsClient

export const cms = createApolloClient({
    ...cmsOptions,
    ...clientBOptions,
});

// Call this in the Vue app file
export function createProvider() {
    // Create vue apollo provider
    const apolloProvider = new VueApollo({
        defaultClient: flirttool.apolloClient,
        clients: {
            apolloClient: flirttool.apolloClient,
            cms: cms.apolloClient
        },
        defaultOptions: {
            $query: {
                fetchPolicy: "network-only"
                    // fetchPolicy: 'cache-and-network',
            }
        },
        errorHandler(error) {
            // eslint-disable-next-line no-console
            console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
        }
    })

    return apolloProvider
}

// Manually call this when user log in
export async function onLogin(apolloClient) {
    console.log("Ayee ", apolloClient)
    apolloClient.cache.data.clear();
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
    try {
        await apolloClient.queryManager.fetchQueryRejectFns;
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (login)', 'color: orange;', e.message)
    }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
    store.commit('setUserData', {})
    store.commit('setFlirtTok', null)
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
    try {
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
    }
}

export const mazuUrl = production ? `https://mazutech.online/` : `http://localhost:8082/`