<style>
.eeeb33c8-89bb-4b0a-b2c5-c86c57d0e4eb {
  fill: #a7afe9;
}
</style>
<template>
  <div id="app" class=" ">
    <notification-bar />
    <popup :showing="showing" @close="showing = false">
      <br>
      <p class="
            px-5
            font-sans
            text-sm text-center text-pink-500
            uppercase
            font-semi-bold
            text-md
            dark:text-white
            card-title
          ">You are running out of flirts, to topup click link below</p>
      <router-link class="mt-3 bg-pink-100 btn" to='/flirtons'>
        Get Flirtons
      </router-link>
    </popup>
    <popup :showing="giftshowing" @close="giftshowing = false">
      <br>
      <p class="
            px-5
            font-sans
            text-sm text-center text-pink-500
            uppercase
            font-semi-bold
            text-md
            dark:text-white
            card-title
          ">You dont have enough flirts to purchase this gift, to topup click link below</p>
      <router-link class="mt-3 bg-pink-100 btn" to='/flirtons'>
        Get Flirtons
      </router-link>
    </popup>
    <!-- <nav-bar class=" " />  -->
    <div class="px-4 py-0  mx-auto sm:max-w-xl hidden lg:block md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-1 ">
      <iconnavbar class="mt-5" /> 
    </div>
    <!-- <dark-nav-bar class="hidden lg:block xl:block"/> -->
    <div
      class="px-4 hidden lg:block py-0 border-0 -mt-24  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-1 "
      style="height: 1000px; ">
      <div
        class="container    flex gap-1  flex-wrap justify-left   ml-auto mr-auto bg-white bg-opacity-0 dark:bg-gray-700 h-1/2 items-top">
        <div
          class=" grid mt-36 rounded-lg  items-top justify-center overflow-hidden h-full grid-cols-1 pl-1 pr-1   bg:opacity-0  dark:bg-gray-600 shadow-md w-30 md:w-49 md:mb-0"
          style="height: 850px; width: 325px;">
          <!-- <user-avatar/> -->
          <div class="bg-yellow-100 dark:bg-gray-600 rounded-2xl" style="background-color: #F3E5CD;">
            <p
              class="mb-2 text-left flex justify-center px-5 font-sans -mt-0 py-3 text-yellow-500 font-lg  font-bold uppercase">
              Chats (Conversation)
            </p>
            <div class="-mt-3 relative py-1 dark:bg-gray-700 " style="height: 850px;   ">
              <YellowsideChatSelector side-chat-selector ref="sideSelector" :loadingChats="loadingList" :newDm="newDm"
                :selected="selected" :allConversations="allConversations" :newTarget="newTarget"
                @userSelected="userSelected" @dmUserSelected="dmUserSelected" @pickDmTarget="pickDmTarget"
                @convoDeleted="convoDeleted" class=" pt-3 " style="height: 900px" />

            </div>
          </div>
      </div>
        <div
          class="grid mt-36 rounded-lg  items-top justify-center  h-full grid-cols-1 pl-1 pr-1   bg:opacity-0  dark:bg-gray-600 shadow-md w-30 md:w-49 md:mb-0 "
          style="height: 850px; width: 600px; background-color: #F3E5CD;">
          <div class="flex flex-col flex-auto flex-shrink-0  bg-pink-50 overflow-auto    dark:bg-gray-600 h-full"
            style="height: 780px; background-color: #F3E5CD;">
            <!-- <nav class="bg:opacity-0  dark:bg-gray-800 bg-pink-00   lg:h-20 xl:h-20 items-start" style="background: #950e51;"></nav> -->
            <div ref="messages" class="flex-auto inset-0 object-cover overflow-y-auto p-5 mt-10 space-y-4 " style=" background-size: cover;
                 background-image: url('https://images.flirttool.com/yellowchatpage.jpg');
                  ">


              <center v-if="loadingConvo">
                <div class="mt-14 lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </center> 

              <d-chat-message v-else v-for="({ id, body, sender, attachment_url, message_type, paid }, i) in allMessages"
                :key="i" :avatar="getURL(sender.profilePic)"
                :messages="[{ id, body, attachment_url, message_type, paid }]"
                :sent="sender.username == $store.state.core.user.username" @showFlirtOn="showing = true"
                @refetchChat="refetchChat" />
            </div>

          </div><message-field :loading="loadingSend"
            v-if="$store.state.core.balance > 0 || $store.state.core.user.has_hpass" :disabled="disabled"
            ref="messageField" @messageSent="messageSent" @photoMessageSent="photoMessageSent" />

          <message-field v-else-if="
            $store.state.core.user.package_activity &&
            $store.state.core.user.package_activity.active
          " :loading="loadingSend" :disabled="disabled" ref="messageFieldx" @messageSent="messageSent"
            @photoMessageSent="photoMessageSent" />
          <div v-else-if="!$store.state.core.user.package_activity"
            class="items-center h-24 mb-3 dark:bg-gray-600 bg-pink-50 w-full md:mb-4">
            <p class="text-center pt-3 dark:text-gray-200">
              You don't have enough credits
            </p>
            <router-link to="/flirts" class="btn dark:bg-pink-200">Get Flirts</router-link>
          </div>
          <div v-else-if="!$store.state.core.user.package_activity.active"
            class="items-center h-24 mb-3 dark:bg-gray-600 bg-pink-50 w-full md:mb-4">
            <p class="text-center pt-3 dark:text-gray-200">
              You don't have enough credits
            </p>
            <router-link to="/flirts" class="btn dark:bg-pink-200">Get Flirts</router-link>
          </div>
        </div>
        <div
          class="grid mt-36 rounded-lg  items-top justify-center  h-full grid-cols-1  pr-1   bg:opacity-0  dark:bg-gray-600 pl-8 w-30 md:w-49 md:mb-0  overflow-auto"
          style="height: 850px; ">

          <div style="height: 380px; "
            class=" items-center justify-center grid-cols-1 w-72   h-screen  pl-0 pr-0 mt- overflow-auto  dark:bg-gray-700 scroll-smooth scrollbar-hide whitespace-nowrap  md:mb-0 bg-white lg:grid-cols-1 md:grid-cols-2">
            <div style="height: ;">
              <p class="-mt-4">
              <p class="-mt-4  text-yellow-500 font-bold">

                VIP USERS
              </p>
              </p>

              <div class="grid grid-cols-2   text-left md:col-span-5 md:grid-cols-2 xl:grid-cols-2">


                <Yellowvipchatsideselector ref="sideSelector" :loadingChats="loadingList" :newDm="newDm"
                  :selected="selected" :allConversations="allConversations" :newTarget="newTarget"
                  @userSelected="userSelected" @dmUserSelected="dmUserSelected" @pickDmTarget="pickDmTarget" />


              </div>
            </div>





          </div>

          <div style="height: 430px; "
            class=" items-center justify-center grid-cols-1 w-72   h-screen  pl-0 pr-0  overflow-auto  dark:bg-gray-700 scroll-smooth scrollbar-hide whitespace-nowrap  md:mb-0 bg-white lg:grid-cols-1 md:grid-cols-2">
            <div style="height: ;">
              <p class="-mt-4  text-yellow-500 font-bold">
                <!-- <img src="@/assets/icons/User.png" class="h-5" alt=""> -->
                MAZU USERS
              </p>

              <div class="grid grid-cols-3   text-left md:col-span-5 md:grid-cols-3 xl:grid-cols-3">
                <mside-chat-selector ref="sideSelector" :loadingChats="loadingList" :newDm="newDm" :selected="selected"
                  :allConversations="allConversations" :newTarget="newTarget" @userSelected="userSelected"
                  @dmUserSelected="dmUserSelected" @pickDmTarget="pickDmTarget" />
              </div>

            </div>





          </div>

        </div>

      </div>
    </div>
<!-- test -->

<!-- emd of test -->

    <!-- mobile chat section starts here -->
    <template>
      <div id="app" class=" lg:hidden xl:hidden 2xl:hidden mb-10">
        <notification-bar />
        <popup :showing="showing" @close="showing = false">
          <br>
          <p class="
                px-5
                font-sans
                text-sm text-center text-pink-500
                uppercase
                font-semi-bold
                text-md
                dark:text-white
                card-title
              ">You are running out of flirts, to topup click link below</p>
          <router-link class="mt-3 bg-pink-100 btn" to='/flirtons'>
            Get Flirtons
          </router-link>
        </popup>
        <popup :showing="giftshowing" @close="giftshowing = false">
          <br>
          <p class="
                px-5
                font-sans
                text-sm text-center text-pink-500
                uppercase
                font-semi-bold
                text-md
                dark:text-white
                card-title
              ">You dont have enough flirts to purchase this gift, to topup click link below</p>
          <router-link class="mt-3 bg-pink-100 btn" to='/flirtons'>
            Get Flirtons
          </router-link>
        </popup>
        <nav-bar class="hidden lg:block xl:block" /> <dark-nav-bar class="hidden lg:block xl:block" />



        <!-- The button to open modal -->

        <div class="flex justify-center bg-white">
          <label for="my-modal-3"
            class="mx-auto grid rounded-lg items-top justify-center overflow-hidden h-full grid-cols-1 pl-1 pr-1 bg:opacity-0 dark:bg-gray-600 shadow-md w-30 md:w-49 md:mb-0">
            <div class="flex justify-between items-center bg-gray-100 p-4">
    <div class="font-bold text-lg">Chat List</div>
    <button class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded">
      New Chats
    </button>
  </div> <!-- <div class="bg-pink-0 dark:bg-gray-600 rounded-2xl">
              <p class="mb-2 text-center px-5 font-sans -mt-0 py-3 bg-white text-pink-500 font-sm font-medium">
                Chats (Conversation)
              </p>
            <div class="relative py-1 dark:bg-gray-700 bg-white flex flex-wrap h-full mb-96 pb-36 "
              style="background-color: #F3E5CD;;">
             
                <YellowsideChatSelector side-chat-selector ref="sideSelector" :loadingChats="loadingList" :newDm="newDm"
                  :selected="selected" :allConversations="allConversations" :newTarget="newTarget"
                  @userSelected="userSelected" @dmUserSelected="dmUserSelected" @pickDmTarget="pickDmTarget"
                  @convoDeleted="convoDeleted" class=" pt-3 " style="height: 900px" />
              </div>
            </div> --> 
            <div class="bg-pink-100 dark:bg-gray-600 rounded-2xl">
  
  <div class="relative py-1 dark:bg-gray-700 bg-white flex flex-wrap h-full mb-96 pb-36" style="background-color: #F3E5CD;">
    <!-- <side-chat-selector ref="sideSelector" :loadingChats="loadingList" :newDm="newDm" :selected="selected"
      :allConversations="allConversations" :newTarget="newTarget" @userSelected="userSelected"
      @dmUserSelected="dmUserSelected" @pickDmTarget="pickDmTarget" @convoDeleted="convoDeleted" class="pt-3" /> -->
    <YellowsideChatSelector
      side-chat-selector
      ref="sideSelector"
      :loadingChats="loadingList"
      :newDm="newDm"
      :selected="selected"
      :allConversations="allConversations"
      :newTarget="newTarget"
      @userSelected="userSelected"
      @dmUserSelected="dmUserSelected"
      @pickDmTarget="pickDmTarget"
      @convoDeleted="convoDeleted"
      class="pt-3"
      style="height: 900px"
    >
      
    </YellowsideChatSelector>
  </div>
</div>

          </label>
        </div>



        <!-- Put this part before </body> tag -->
        <input type="checkbox" id="my-modal-3" class="modal-toggle" />
        <div class="modal ">
          <div class="modal-box  bg-white  h-screen   relative">

            <label for="my-modal-3" class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
            <div class="flex flex-col flex-auto flex-shrink-0  bg-white py-10     dark:bg-gray-700 h-full">

              <div ref="messages" class="flex-auto overflow-y-auto p-0  space-y-4 mt-0" style=" background-size: cover;
                 background-image: url(https://images.flirttool.com/chat.jpg);
                      ">
                <center v-if="loadingConvo">
                  <div class="mt-14 lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </center>

                <d-chat-message v-else
                  v-for="({ id, body, sender, attachment_url, message_type, paid }, i) in allMessages" :key="i"
                  :avatar="getURL(sender.profilePic)" :messages="[{ id, body, attachment_url, message_type, paid }]"
                  :sent="sender.username == $store.state.core.user.username" @showFlirtOn="showing = true"
                  @refetchChat="refetchChat" />
              </div>
              <message-field :loading="loadingSend"
                v-if="$store.state.core.balance > 0 || $store.state.core.user.has_hpass" :disabled="disabled"
                ref="messageField" @messageSent="messageSent" @photoMessageSent="photoMessageSent" />

              <message-field v-else-if="
                $store.state.core.user.package_activity &&
                $store.state.core.user.package_activity.active
              " :loading="loadingSend" :disabled="disabled" ref="messageFieldx" @messageSent="messageSent"
                @photoMessageSent="photoMessageSent" />
              <div v-else-if="!$store.state.core.user.package_activity"
                class="items-center h-24 mb-3 dark:bg-gray-600 bg-pink-50 w-full md:mb-4">
                <p class="text-center pt-3 dark:text-gray-200">
                  You don't have enough credits
                </p>
                <router-link to="/flirts" class="btn dark:bg-pink-200">Get Flirts</router-link>
              </div>
              <div v-else-if="!$store.state.core.user.package_activity.active"
                class="items-center h-24 mb-3 dark:bg-gray-600 bg-pink-50 w-full md:mb-4">
                <p class="text-center pt-3 dark:text-gray-200">
                  You don't have enough credits
                </p>
                <router-link to="/flirts" class="btn dark:bg-pink-200">Get Flirts</router-link>
              </div>
            </div>
          </div>
        </div>

<!-- 
        <mobilenavbar /> -->

      </div>
    </template>
    <page-footer class="mt-36 hidden lg:block" />
  </div>
</template>
<script>
import SideChatSelector from "@/components/sideChatSelector.vue";
import MsideChatSelector from "@/components/yellow/msideChatSelector.vue";
// import MobileSideSelector from "../../components/mobilesideSelector.vue";
import { getURL } from "@/utils/helpers";
import DChatMessage from "@/components/yellow/ChatMessage.vue";
import UserAvatar from "@/components/userAvatar.vue";
import selectedUseravatar from "@/components/selectedUseravatar.vue";
// import UserCard from "../../components/userCard.vue";
// import SideHomeSelector from "../../components/sideHomeSelector.vue";
import NewSideSelector from "@/components/newsideselector ";
import navBar from "@/components/navBar.vue";
import Vipchatsideselector from "@/components/Vipchatsideselector.vue";
import YellowsideChatSelector from "@/components/yellow/YellowsideChatSelector.vue"
import yellownavBar from "@/components/yellow/yellownavBar.vue";
import iconnavbar from "@/components/yellow/iconnavbar.vue"

import {
  ALL_CONVERSATIONS,
  CONV_STAT,
  GET_MESSAGES,
  MESSAGE_LISTEN,
  SEND_MESSAGE,
  USER_CONVERSATION
} from "../../queries/chat";
import MessageField from "@/components/yellow/chat/messageField.vue";
import DarkNavBar from "../../components/darknavBar.vue";
import { DM_TARGET_INFO } from "../../queries/auth";
import NotificationBar from "../../components/NotificationBar.vue";
import DarkNewSideSelector from "@/components/darknewsideselector";
// import MobileSideSelector from "../../components/mobilesideselector.vue";
import SideMatchSelector from '@/components/sideMatchSelector'
import popup from "../../components/popup.vue";
import Mobilenavbar from "../../components/yellow/Mobilenavbar.vue";
import pageFooter from "../../components/pageFooter.vue";
import Yellowvipchatsideselector from "@/components/yellow/YellowVipchatsideselector.vue"

export default {
  components: {
    SideChatSelector,
    // MobileSideSelector,
    // MobileSideSelector,
    MessageField,
    DChatMessage,
    yellownavBar,
    DarkNavBar,
    navBar,
    NotificationBar,
    UserAvatar,
    NewSideSelector,
    selectedUseravatar,
    Vipchatsideselector,
    YellowsideChatSelector,
    Yellowvipchatsideselector,
    iconnavbar,
    // UserCard,
    DarkNewSideSelector,
    // SideHomeSelector,
    MsideChatSelector,
    SideMatchSelector, Mobilenavbar, pageFooter,
    popup
  },
  apollo: {
    allConversations: {
      query: ALL_CONVERSATIONS,
      fetchPolicy: 'no-cache',
      result({ data: { allConversations } }) {
        this.allConversations.forEach((cv) => {
          // console.log(new Date(parseInt(cv.lastMessage.createdAt).toLocaleTimeString()),"SHEESH")
          if (cv.lastMessage)
            cv.lastMessage.createdAt = new Date(
              parseInt(cv.lastMessage.createdAt)
            ).toLocaleTimeString();
        });
        if (this.$route.query.dm) {
          this.fetchDmTargetInfo();
          // this.$apollo.queries.dmTargetInfo.refetch()
        }
        const target = parseInt(this.$route.query.dm);
        const userInConvo = allConversations.find((e) => {
          if (e.m_one.id === target || e.m_one.id === target) {
            return true;
          }
        });
        console.log(userInConvo, "IN CONV");
        if (userInConvo) this.userSelected(userInConvo);

        this.loadingList = false;

        return allConversations;
        // this.allConversations = allConversations
      },
    },
    $subscribe: {
      messageAdded: {
        query: MESSAGE_LISTEN,
        variables() {
          return {};
        },
        result({ data }) {
          const message = data.messageAdded.mes;
          console.log(this.selectedConvo, "LL");

          if (this.$route.query.dm && !this.selectedConvo) {
            console.log("inside DM", message.sender.username);
            console.log(this.newTarget.username);
            if (
              message.sender.username == this.newTarget.username ||
              message.sender.username === this.$store.state.core.user.username
            ) {
              this.allMessages.push(message);
              const container = this.$refs.messages;
              setTimeout(() => {
                container.scrollTop = container.scrollHeight;
              }, 200);
              // } else {
              // 	this.allMessages.push(message)
              // 	console.warn(message,"MSG")
              // alert(message.body)
              console.log("whoaaaaaaaaaaaaaaaaaaaaaaaaaaa IS DM");
              console.log(message.sender);
              console.log(this.newTarget);
              console.log("whoaaaaaaaaaaaaaaaaaaaaaaaaaaa");
            }
          } else if (this.selectedConvo) {
            if (
              message.sender.username === this.selectedConvo.m_one.username ||
              message.sender.username === this.$store.state.core.user.username
            ) {
              this.allMessages.push(message);
              const container = this.$refs.messages;
              setTimeout(() => {
                container.scrollTop = container.scrollHeight;
              }, 200);
              // } else {
              console.log("whoaaaaaaaaaaaaaaaaaaaaaaaaaaa NOT DM");
              console.log(message.sender);
              console.log(this.selectedConvo.m_one);
              console.log("whoaaaaaaaaaaaaaaaaaaaaaaaaaaa");
              // alert(message.body)
            }
          }
        },
      },
    },
    convoStatus: {
      query: CONV_STAT,
      update: (data) => {
        return data.convoStatus;
      },
    },
  },
  methods: {
    getURL,
    fetchDmTargetInfo() {
      this.$apollo
        .query({
          query: DM_TARGET_INFO,
          fetchPolicy: "network-only",
          variables: {
            dtarget: this.$route.query.dm,
          },
        })
        .then(
          ({
            data: {
              dmTargetInfo: { target, convExsists },
            },
          }) => {
            console.log(target, "TARG", convExsists);
            this.newTarget = target;
            if (!convExsists) {
              this.newDm = true;
              this.disabled = false;
            } else {
              console.log("_________________________________");
              // for (let ix = 0; ix < this.allConversations.length; ix++) {
              //   const el = this.allConversations[ix];
              //   console.log(el.m_one.username, "FRAGILE");
              // }
              console.log(this.allConversations, "WOOWW");
              console.log("_________________________________");
              const selected = this.allConversations.find(
                (cv) => cv.m_one.username === target.username
              );
              if (!selected) {
                // alert('in here')
                this.$apollo
                  .query({
                    query: USER_CONVERSATION,
                    fetchPolicy: "network-only",
                    variables: {
                      with_user: Number(this.$route.query.dm),
                    },
                  })
                  .then(({ data }) => {
                    // alert('finally')
                    // console.log(data)
                    let exists = this.allConversations.find(x => data.getUserConversation.id == x.id)
                    if (!exists) this.allConversations.push(data.getUserConversation)
                    console.log(selected, "SELECTED");
                    this.selectedConvo = data.getUserConversation;
                    // this.fetchConvo(selected)
                    console.log(selected, "YEAA");
                    this.userSelected(data.getUserConversation);
                  })
                  .finally(() => {
                    this.loadingConvo = false;
                  })
                  .catch(() => {
                    this.loadingConvo = false;
                  });
              } else {
                // here USER_CONVERSATION
                console.log(selected, "SELECTED");
                this.selectedConvo = selected;
                // this.fetchConvo(selected)
                console.log(selected, "YEAA");
                this.userSelected(selected);
                // console.log(this.allConversations, "FUCK")
                // console.log(target)
              }

            }
          }
        )
        .catch((e) => {
          console.log("THERE WAS AN ERROR")
          // this.$refs.sideSelector.pickDmTarget();
          console.error(e);
        });
    },
    photoMessageSent(message, file) {
      this.loadingSend = true;
      this.$apollo
        .mutate({
          mutation: SEND_MESSAGE,
          variables: {
            input: {
              body: message,
              user: this.selectedConvo
                ? this.selectedConvo.m_one.username
                : this.newTarget.username,
              attachment: "",
            },
            file: file.target.files[0],
          },
        })
        .then(({ data }) => {
          this.loadingSend = false;
          if (data) {
            this.$refs.messageFieldx
              ? this.$refs.messageFieldx.emptyMessageField()
              : this.$refs.messageField.emptyMessageField();
            this.$store.commit("setBalance", data.sendMessage.flirts);
            if (this.$store.getters.showFlirton) this.showing = true
          }
        })
        .catch((e) => {
          this.loadingSend = false;
          if (e.message == "GraphQL error: Spam detected") {
            this.$notify(
              {
                group: "top",
                title: "chat",
                text: "please give them a minute",
                type: "info", // info, warning
              },
              5000
            );
          }
        });
      console.log(message, file);
    },
    messageSent(message) {
      if (message.body.length >= 1 || message.type == 'g') {
        console.log(message)
        this.$apollo
          .mutate({
            mutation: SEND_MESSAGE,
            variables: {
              input: {
                body: message.body,
                user: this.selectedConvo
                  ? this.selectedConvo.m_one.username
                  : this.newTarget.username,
                attachment: message.attachment,
                message_type: message.type,
                attachment_url: message.attachment_url,
                ...(message.type == 'g' && { gift_id: message.gift_id }),
              },
            },
          })
          .then(({ data }) => {
            if (data) {
              this.$refs.messageFieldx
                ? this.$refs.messageFieldx.emptyMessageField()
                : this.$refs.messageField.emptyMessageField();
              this.$store.commit("setBalance", data.sendMessage.flirts);
              if (this.$store.getters.showFlirton) this.showing = true
            }
          })
          .catch((e) => {
            if (e.message == "GraphQL error: Spam detected") {
              this.$notify(
                {
                  group: "top",
                  title: "chat",
                  text: "please give them a minute",
                  type: "info", // info, warning
                },
                5000
              );
            } else if (e.message == "GraphQL error: You don't have enough flirts") {
              this.giftshowing = true
            }
          });
      } else {
        alert("can't send empty message");
      }
    },
    onResize() {
      // TODO: match query params back on routing
      if (window.innerWidth >= 1200) {
        console.log("Extra Large", window.innerWidth);
      } else if (window.innerWidth >= 992) {
        console.log("Large", window.innerWidth);
      } else if (window.innerWidth >= 768) {
        if (this.convoSelected) {
          this.$router.push("/chat-details/" + this.selectedConvo.id);
          return;
        } else {
          this.$router.push(
            this.$route.query.dm ? `/chat-list?dm=${this.$route.query.dm}` : "/chat-list"
          );
        }
        console.log("Medium", window.innerWidth);
      } else if (window.innerWidth >= 576) {
        if (this.convoSelected) {
          this.$router.push("/chat-details/" + this.selectedConvo.id);
          return;
        } else {
          this.$router.push("/chat-list");
        }
        console.log("Small", window.innerWidth);
      } else if (window.innerWidth < 576) {
        if (this.convoSelected) {
          this.$router.push("/chat-details/" + this.selectedConvo.id);
          return;
        } else {
          this.$router.push("/chat-list");
        }

        console.log("X-Small");
      }
    },
    refetchChat() {
      this.fetchConvo(this.selected)
    },
    fetchConvo(conv) {
      console.warn(conv, "GOT HERE");
      // this.loadingConvo = true
      this.$apollo
        .query({
          query: GET_MESSAGES,
          fetchPolicy: "no-cache",
          variables: {
            conv: conv.id,
          },
        })
        .then(({ data }) => {
          this.selectedConvo = conv;
          this.allMessages = data.allMessages.messages;
          let messages = data.allMessages.messages;
          this.renderedMessages = messages;
          const container = this.$refs.messages;
          this.disabled = false;
          setTimeout(() => {
            container.scrollTop = container.scrollHeight;
          }, 200);
          this.loadingConvo = false;
        })
        .finally(() => {
          this.loadingConvo = false;
        })
        .catch(() => {
          this.loadingConvo = false;
        });
    },
    array_move(arr, old_index, new_index) {
      new_index = ((new_index % arr.length) + arr.length) % arr.length;
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    userSelected(convo) {
      this.selected = convo;
      // sort array and put the new conversation to top on select
      if (this.newDm)
        this.array_move(this.allConversations, this.allConversations.indexOf(convo), 0);
      this.convoSelected = true;
      this.loadingConvo = true;
      this.fetchConvo(convo);
    },
    dmUserSelected() {
      this.convoSelected = true;
    },
    pickDmTarget() {
      this.$apollo.queries.allConversations.refetch();
      // this.loadingChats = false
    },
    convoDeleted(conversation) {
      console.log(this.allConversations, "before")
      let updated_convos = this.allConversations.filter(x => x.id != conversation.id)
      this.allConversations = [...updated_convos]
      console.log(this.allConversations, "after")
      if (this.allConversations.length) {
        setTimeout(() => {
          this.userSelected(this.allConversations[0])
        }, 3000)
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    if (this.$store.getters.showFlirton) this.showing = true
    this.$apollo.queries.allConversations.refetch();
    if (!this.$route.query.dm) {
      this.$apollo.subscriptions.messageAdded.refresh();
    }
    this.$apollo.queries.convoStatus.refetch();
    window.addEventListener("resize", this.onResize);
  },
  data: () => ({
    loadingContent: false,
    renderedMessages: [],
    allMessages: [],
    loadingSend: false,
    allConversations: [],
    selectedConvo: null,
    convoStatus: false,
    // loadingChats: false,
    showing: false,
    giftshowing: false,
    loadingList: true,
    loadingConvo: false,
    disabled: true,
    newTarget: {},
    newDm: false,
    convoSelected: false,
    bgColor: "#dc4b78",
    selected: {},
    position: "bottom-right",
    fabActions: [
      {
        name: "toMatches",
        icon: "favorite",
      },
    ],
  }),
};
</script>
<style>@media screen and (max-width: 640px) {
  .modal-box {
    height: 100vh;
  }
}</style>
