
<style>
#my-textfield::placeholder {
    color: rgb(154, 154, 154);
}
</style>

<template>
    <div>
        <!-- notification -->
        <notificationGroup group="top" position="top">
            <div style="transform: scale(80%)" class="
          fixed
          inset-x-0
          z-10
          flex
          items-start
          justify-end
          p-6
          px-4
          py-6
          mt-5
          pointer-events-no
        ">
                <div class="w-full max-w-sm">
                    <notification v-slot="{ notifications }">
                        <div class="
                flex
                w-full
                max-w-sm
                mx-auto
                mt-4
                overflow-hidden
                bg-white
                rounded-lg
                shadow-md
              " v-for="notification in notifications" :key="notification.id">
                            <div class="flex items-center justify-center w-12 bg-pink-500">
                                <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z">
                                    </path>
                                </svg>
                            </div>

                            <div class="px-4 py-2 -mx-3">
                                <div class="mx-3 text-left">
                                    <span class="font-semibold text-pink-500">{{
                                        notification.title
                                    }}</span>
                                    <p class="text-sm text-gray-600">{{ notification.text }}</p>
                                </div>
                            </div>
                        </div>
                    </notification>
                </div>
            </div>
        </notificationGroup>



        <!-- notification -->




        <div class="flex h-screen w-full items-center justify-center bg-gray-900 bg-cover bg-no-repeat"
        style="object-fit: cover; width: 100%; height: 100%"> <img style="height: 1100px" :src="require('@/assets/purplethemefiles/ballon.jpg')" 
          class="absolute inset-0 justify-center object-cover w-full z-0 h-full sm:" alt="" />
            <div class="rounded-xl bg-gray-100 z-50 bg-opacity-90 px-0 py-10 px-3 shadow-2xl backdrop-blur-md max-sm:px-0 lg:mt-28 border-b-8 border-pink-400 ">
                <div class="text-white py-5">
                    <div class="mb-8 flex flex-col items-center">
                        <!-- <img src="https://www.logo.wine/a/logo/Instagram/Instagram-Glyph-Color-Logo.wine.svg" width="140"
                            alt="" srcset="" /> -->
                        <!-- <h1 class="mb-2 text-4xl text-gray-900 uppercase">M<span class="text-pink-700">AZU login</span> --> 
                            <img :src="getCommunityMeta.logo" class="h-16" alt="">
                       
                      <router-link to="/register">  <span class="text-gray-900 font-bold uppercase mt-3 font-sans italic">{{$t('OrCreateanAccount')}}</span></router-link>
                    </div>
                    <form v-if="!loadingSocialAuth" class="">
                        <div class="bg-pink-100 alert" v-if="authErr">
                            <div class="flex-1">
                                <label>
                                    {{ authErr }}
                                </label>
                            </div>
                        </div>
                        <div class="mb-3"> <span
                                class="text-pink-50 bg-pink-600 px-2 rounded-lg py-1 uppercase ">{{$t('UsernameorEmail')}}</span></div>
                        <div class="mb-4 text-lg  ">

                            <input @blur="usernameFocus = false" @focus="usernameFocus = true"
                                v-model.trim="$v.emailOrUname.$model" @input="setUname($event.target.value)"
                                class="rounded-xl border-4 bg-pink-50 bg-opacity-50 text-gray-800 px-6 py-2 w-90 text-center text-inherit placeholder-slate-200 shadow-lg outline-none backdrop-blur-md"
                                :class="{
                                    'border-gray-50 focus:ring-2 focus:ring-pink-500':
                                        !$v.emailOrUname.required,
                                }" id="my-textfield" type="text" value="" placeholder="user@"
                                @keydown.enter="doLogin" />
                            <div class="text-red-500 font-sans uppercase text-center text-xs error"
                                v-if="!$v.emailOrUname.required && usernameFocus">
                                Field is required
                            </div>
                            <div class="  text-red-500 font-sans uppercase text-xs mt-2 font-bold text-center error"
                                v-if="!$v.emailOrUname.minLength">
                                Username Contains More than
                                {{ $v.emailOrUname.$params.minLength.min }} letters.
                            </div>
                        </div>

                        <div class="mb-3"> <span class="text-pink-50 bg-pink-600 px-2 rounded-lg py-1 uppercase ">{{$t('YourPassword')}}</span></div>
                        <div class="mb-4 text-lg">
                            <input
                                class="rounded-xl border-4 text-gray-800 bg-pink-50 w-90 bg-opacity-50 px-6 py-2 text-center text-inherit placeholder-slate-200 shadow-lg outline-none backdrop-blur-md"
                                @blur="passFocus = false" @focus="passFocus = true" @keydown.enter="doLogin"
                                @input="setPass($event.target.value)" v-model.trim="$v.password.$model" :class="{
                                    'border-white focus:ring-2 focus:ring-pink-500':
                                        !$v.password.required,
                                }" id="my-textfield" :type="showPass ? 'text' : 'password'" value=""
                                placeholder="**********">
                            <!-- <div class="">
                                <button type="button" class="absolute
    text-right
    mt-2
    btn-sm
    bg-pink
    btn-outline btn btn-primary
    border-0
  " @click="showPass = !showPass">
                                    <svg v-if="showPass" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="white" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                        <path
                                            d="M8 0c-3.01 0-5.83 1.11-8 2.99 2.43 2.47 4.03 4.44 8 4.44s5.57-1.97 8-4.44C13.83 1.11 11.01 0 8 0zM1.146 1.146a.5.5 0 0 1 .708 0l12 12a.5.5 0 0 1-.708.708l-12-12a.5.5 0 0 1 0-.708z" />
                                        <path
                                            d="M2.293 2.293a12.07 12.07 0 0 1 .264 1.498C1.55 4.676 0 6.788 0 8c0 2.485 2.015 4.5 4.5 4.5a9.8 10.0 0 0 0 1.496-.265.5.5 0 0 1 .727.543c-.845 3.92-3.83 6.91-7.75 7.75a.5.5 0 0 1-.543-.727z" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                        <path d="M8 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                        <path fill-rule="evenodd"
                                            d="M1 8s3-5.5 7-5.5S15 8 15 8s-3 5.5-7 5.5S1 8 1 8zm7-3.5a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                    </svg>
                                </button>


                            </div> -->

                            <div class="text-red-500 font-sans uppercase text-center text-xs error"
                                v-if="!$v.password.required && passFocus">
                                Password is required
                            </div>
                            <p class="mx-2 mt-1 font-sans text-gray-700 uppercase italic text-sm font-sans">
                            {{$t('Forgotpassword')}} ?
                                <router-link to="/initial-reset"
                                    class="font-semi-bold italic text-pink-800 font-bold font-sans">{{$t('Reset')}}</router-link>
                            </p>
                        </div>




                        <div
                            class=" text-center flex justify-center mx-auto w-full font-sans font-semi-bold text-pink-500 error">

                            <label class="inline-flex items-center mt-3 text-left">
                                <input @change="termsChanged" v-model="acceptedTerms" type="checkbox" class="
                          w-5
                          h-5
                          border-2 border-pink-500
                          text-pink-600
                          form-checkbox
                        " checked /><span class="
                          mx-4
                          mb-0
                          text-2xs
                          mt-0 w-72
                          font-bold
                          text-left text-gray-500
                          uppercase
                          dark:text-gray-800
                         
                          text-light
                        ">{{$t('IAGREE')}}
                                    <a class="text-blue-400" href="https://flirttool.com/terms">{{$t('Clickhereto')}}</a>
                                </span>
                            </label>
                        </div>



                        <div class="mt-8 flex justify-center text-lg text-black">
                            <button type="submit" :disabled="loadingLogin" @click="doLogin" style="cursor: pointer"
                                class="rounded-3xl bg-pink-700 glass bg-opacity-50 px-10 py-2 text-white shadow-xl backdrop-blur-md transition-colors duration-300 hover:bg-yellow-600">
                                <div v-if="loadingLogin" class="mt-3 lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <p class="-mt-5 uppercase" v-else>{{$t('SignIn')}}</p>
                            </button>
                        </div>
                        <div class="flex flex-wrap  justify-center gap-5 mx-auto mt-5 gap-0 mt-1 md:grid-cols-1 sm:grid-cols-1">
                            <div class=" mt-0 mr-0 md:mr-0  hover:text-white">
                                <button type="button" @click="socialLoginGoogle" class="
                    mt-0
                    mb-3
                    bg-gray-50  bg-opacity-0
                   
                    hover:bg-pink-400
                    hover:text-white
                    rounded-lg
                    shadow-2xl
                    
                  ">
                                    <img :src="require('@/assets/google_logo_png_suite_everything_you_need_know_about_google_newest.png')"
                                        class="w-10 h-10"
                                        alt="Connect with flirttool & start your personal online dating adventure!" />
                                    <!-- <span
                    class="px-2 font-sans text-sm  hover:text-white text-gray-700 dark:text-white"
                  >
                   Log in with Google
                  </span> -->
                                </button>
                            </div>
                            <div class=" mr-0 md:mr-0">
                                <button type="button" @click="socialLoginFb" class="
                   mt-0
                    mb-3
                    
                   
                    hover:bg-pink-400
                    hover:text-white
                    rounded-lg
                    shadow-2xl
                    
                  ">
                                    <img :src="require('@/assets/Facebook_f_logo_(2019).svg.webp')" class="w-10 h-10"
                                        alt="Connect with flirttool & start your personal online dating adventure!" />

                                    <!-- <span
                    class="px-2 font-sans text-sm text-gray-700  hover:text-white  dark:text-white"
                  >
                    Continue with Facebook
                  </span> -->
                                </button>
                            </div>
                        </div>
                    </form> <center v-else class="mt-10">
            <div class="mt-3 lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </center>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import axios from "axios";
import { UPDATE_LOC } from "@/queries/matches";
import { createProvider, onLogin } from "@/v-apollo";
import { required, minLength } from "vuelidate/lib/validators";
import { SOCIAL_AUTH_GOOGLE, USER_LOGIN } from "@/queries/auth";
import { getCommunityMeta } from "@/utils/helpers.js";
import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    getAuth,
    signInWithPopup,
} from "firebase/auth";
export default {
    data: () => ({
        getCommunityMeta,
        showPass: false,
        emailOrUname: "",
        password: "",
        passFocus: false,
        loadingLogin: false,
        usernameFocus: false,
        authErr: "",
        acceptedTerms: false,
        loadingSocialAuth: false,
    }),
    created() {
        console.clear();
        console.warn(this.$route.query, "dog");
        this.setAddress();
        if (this.$store.state.core.flirtok) {
            this.$router.push("/find-matches");
        }
    },
    methods: {
        setAddress() {
            axios
                .get(
                    "https://ipapi.co/json/?key=VIqRa8XLW2h12erXOpubgCtj0xrCgkG6JHe8vNXuEfi7PMRo29"
                )
                .then(({ data }) => {
                    this.$store.commit("setAddr", data.ip);
                    this.$apollo.mutate({
                        mutation: UPDATE_LOC,
                        variables: {
                            addr: data.ip,
                        },
                    });
                });
        },
        socialLoginFb() {
            if (!this.acceptedTerms) {
                this.$notify(
                    {
                        group: "top",
                        title: "you need to agree with the terms before you contine",
                        text: "Auth Error",
                        type: "info", // info, warning
                    },
                    5000
                );
                return;
            }
            const auth = getAuth();
            const provider = new FacebookAuthProvider();
            this.loadingSocialAuth = true;
            signInWithPopup(auth, provider)
                .then(async (result) => {
                    // const credential = GoogleAuthProvider.credentialFromResult(result)
                    console.warn("*************************");
                    console.warn(result);
                    console.warn("*************************");
                    await this.$apollo
                        .mutate({
                            mutation: SOCIAL_AUTH_GOOGLE,
                            variables: {
                                addr: this.$store.state.core.addr,
                                input: result.user.accessToken,
                            },
                        })
                        .then(
                            ({
                                data: {
                                    socialMediaRegisterGoogle: { errors, user, credit, token },
                                },
                            }) => {
                                // onLogin(createProvider().defaultClient)
                                this.$store.commit("setUserData", user);
                                credit.flirtonsAvailable === true
                                    ? this.$store.commit("setFlirtonsAvailable", true) :
                                    this.$store.commit("setFlirtonsAvailable", false)
                                credit
                                    //? this.$store.commit("setBalance", credit.balance)
                                    ? this.$store.commit("setBalance", credit.balance + credit.flirtons)
                                    : null,
                                    this.$store.commit("setRandState", Math.random(100));
                                this.$store.commit("setFlirtTok", token);
                                // reset apollo client
                                // console.log(user, "user is logged in");
                                // window.location.assign("/")
                                if (user.profileComplete) {
                                    // this.$router.push("/find-matches");
                                    if (this.$route.query.to) {
                                        window.location.assign("/" + this.$route.query.to);
                                    } else {
                                        window.location.assign("/find-matches");
                                    }
                                } else {
                                    this.$router.push("/profile-setup");
                                }
                                console.error(errors);
                                console.warn(user);
                            }
                        );
                })
                .catch((err) => {
                    const errCode = err.code;
                    console.error(err, errCode);
                })
                .finally(() => {
                    this.loadingSocialAuth = false;
                });
        },
        socialLoginGoogle() {
            if (!this.acceptedTerms) {
                this.$notify(
                    {
                        group: "top",
                        title: "you need to agree with the terms before you continue",
                        text: "Auth Error",
                        type: "info", // info, warning
                    },
                    5000
                );
                return;
            }
            const auth = getAuth();
            const provider = new GoogleAuthProvider();
            this.loadingSocialAuth = true;
            signInWithPopup(auth, provider)
                .then(async (result) => {
                    // const credential = GoogleAuthProvider.credentialFromResult(result)
                    await this.$apollo
                        .mutate({
                            mutation: SOCIAL_AUTH_GOOGLE,
                            variables: {
                                addr: this.$store.state.core.addr,
                                input: result.user.accessToken,
                                userType: '',
                                preference: ""
                            },
                        })
                        .then(
                            ({
                                data: {
                                    socialMediaRegisterGoogle: { errors, user, credit, token },
                                },
                            }) => {
                                onLogin(createProvider().defaultClient);
                                this.$store.commit("setUserData", user);
                                credit.flirtonsAvailable === true
                                    ? this.$store.commit("setFlirtonsAvailable", true) :
                                    this.$store.commit("setFlirtonsAvailable", false)
                                credit
                                    //? this.$store.commit("setBalance", credit.balance)
                                    ? this.$store.commit("setBalance", credit.balance + credit.flirtons)
                                    : null,
                                    this.$store.commit("setRandState", Math.random(100));
                                this.$store.commit("setFlirtTok", token);
                                // reset apollo client
                                // console.log(user, "user is logged in");
                                // window.location.assign("/")
                                if (user.profileComplete) {
                                    if (this.$route.query.to) {
                                        window.location.assign("/" + this.$route.query.to);
                                    } else {
                                        window.location.assign("/find-matches");
                                    }
                                } else {
                                    this.$router.push("/profile-setup");
                                }
                                console.error(errors);
                                console.warn(user);
                            }
                        );
                })
                .catch((err) => {
                    const errCode = err.code;
                    console.error(err, errCode);
                })
                .finally(() => {
                    this.loadingSocialAuth = false;
                });
        },
        doLogin() {
            if (!this.acceptedTerms) {
                this.$notify(
                    {
                        group: "top",
                        title: "you need to agree with the terms before you contine",
                        text: "Auth Error",
                        type: "info", // info, warning
                    },
                    5000
                );
                return;
            }
            if (!this.$v.$invalid) {
                this.loadingLogin = true;
                this.$apollo
                    .getClient()
                    .mutate({
                        mutation: USER_LOGIN,
                        // authErrs: [],
                        variables: {
                            addr: this.$store.state.core.addr,
                            usernameOrEmail: this.emailOrUname,
                            password: this.password,
                        },
                    })
                    .then(({ data: { login } }) => {
                        console.log(login, "What the heck");
                        const { user, credit, token } = login;
                        credit.flirtonsAvailable === true
                            ? this.$store.commit("setFlirtonsAvailable", true) :
                            this.$store.commit("setFlirtonsAvailable", false)
                        onLogin(createProvider().defaultClient);
                        this.$store.commit("setUserData", user);
                        //this.$store.commit("setBalance", credit.balance);
                        this.$store.commit("setBalance", credit.balance + credit.flirtons);
                        this.$store.commit("setRandState", Math.random(100));
                        this.$store.commit("setFlirtTok", token);
                        // reset apollo client
                        // console.log(user, "user is logged in");
                        // window.location.assign("/")
                        if (user.profileComplete) {
                            // this.$router.push("/find-matches");
                            if (this.$route.query.to) {
                                window.location.assign("/" + this.$route.query.to);
                            } else {
                                window.location.assign("/find-matches");
                            }
                        } else {
                            this.$router.push("/profile-setup");
                        }
                    })
                    .catch((e) => {
                        console.warn(Object.keys(e));
                        Object.keys(e).forEach((x) => {
                            console.warn(e[x]);
                        });
                        console.error(e);
                        this.authErr = "You have entered incorrect credentials";
                    })
                    .finally(() => {
                        this.loadingLogin = false;
                    });
            }
        },
        setUname(value) {
            this.emailOrUname = value;
            this.$v.emailOrUname.$touch();
        },
        setPass(value) {
            this.password = value;
            this.$v.password.$touch();
        },
    },
    validations: {
        emailOrUname: {
            required,
            minLength: minLength(4),
        },
        password: {
            required,
        },
    },
    metaInfo() {
        return {
            title: "Login | FlirtTool | The finest global  dating website around. ",
            meta: [
                {
                    name: "description",
                    content:
                        " FlirtTool is the first dating community based on a special artificial intelligence (AI) system. Connect with local singles & start your personal online dating adventure!",
                },
                {
                    property: "og:title",
                    content:
                        "Login | FlirtTool | The finest global  dating website around.",
                },
                { property: "og:site_name", content: "FlirtTool Online Dating" },
                { property: "og:type", content: "FlirtTool Online Dating" },
                { name: "googlebot", content: "index,follow" },
            ],
        };
    },
};
</script>