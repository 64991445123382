<template>
  <div
    class="flex justify-center col-span-3 -mt-16 bordered sm:mx-5 lg:mx-0 lg:pl-5 xl:pl-24"
  ><template>
  <ul
    class="py-2 list-reset md:py-0"
    v-scroll-spy-active="{ class: 'customActive' }"
  >
    <li
      v-for="(item, idx) in sections"
      :key="idx"
      :class="{ customActive: item.link == $route.path }"
      class="
        py-1
        border-l-4 border-transparent
        md:my-2
        hover:text-pink-500
        lg:hover:bg-transparent
        font-regular
      "
    >
      <router-link
        :to="item.link"
        class="
          block
          pl-4
          text-gray-700
          no-underline
          align-middle
          dark:text-gray-200 dark:hover:text-pink-500
          hover:text-pink-500
        "
      >
        <span
          class="
            w-full
            mb-1
            font-sans
            text-lg text-left text-gray-500
            uppercase
            shadow-sm
            btn
            md:text-sm
            dark:text-gray-200
            text-light
          "
          >{{ item.title }}</span
        >
        <!-- <a
				@click="$router.push()"
			>
				<span class="pb-1 pr-10 font-sans text-lg text-left md:pb-0">{{
					item.title
				}}</span>
			</a> -->
      </router-link>
    </li>
  </ul>
</template>
    <!-- Content loader start -->
    <div class="lds-ripple mt-44" v-if="!me">
      <div></div>
      <div></div>
    </div>
    <!-- Content loader end -->

    <div
      v-else
      class="grid w-full max-w-6xl grid-cols-1 lg:grid-cols-11 lg:mt-16 lg:mb-96 md:mx-20"
      style=""
    >
      <div class="col-span-3 bordered sm:mx-5 lg:mx-0">
        <div v-if="loadingProfile">
          <div class="h-56 mx-auto mt-5 bg-gray-500 rounded-md animate-pulse"></div>
          <div class="space-y-2">
            <div class="h-4 mt-3 bg-gray-500 rounded animate-pulse"></div>
          </div>
        </div>

        <div class="rounded-none shadow-md card dark:bg-gray-800" v-else>
          <div class="cursor-pointer card-body">
            <figure>
              <img :src="getURL(me.user.profilePic)" @click="profileClicked" class="" />
              <input @change="upload" ref="profileInput" type="file" class="hidden" />
            </figure>
            <button
              :style="
                blendColor == '' ? '' : `background-color: ${blendColor};opacity: 0.7;`
              "
              :class="{
                'text-white': lightOrDark(blendColor) == 'dark',
                'text-gray-600': lightOrDark(blendColor) == 'light',
              }"
              class="bg-pink-100 rounded-none btn hover:text-pink-500"
              @click="uploadProfilePic"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </button>
            <div class="my-3 lg:hidden xl:hidden">
              <div class="mt-5 rounded-none shadow-none card lg:mx-3">
                <div
                  class="grid grid-cols-1 font-black card-body dark:bg-gray-800 lg:grid-cols-2 sm:grid-cols-1"
                >
                  <div>
                    <h1 class="text-4xl text-left dark:text-white">
                      <span class="font-sans text-2xl font-bold text-pink-500 uppercase">
                        {{ me.user.base_profile.firstName }}
                        {{ me.user.base_profile.lastName }}</span
                      >
                      <p
                        style="font-size: 17px"
                        class="pt-5 font-sans font-normal text-left dark:text-gray-200"
                        v-if="me.user.user_basic"
                      >
                        {{ me.user.user_basic.bio }}
                      </p>
                    </h1>
                  </div>
                  <div class="card-actions dark:text-white">
                    <!-- likes count block -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="font-sans w-7 h-7"
                      viewBox="0 0 20 20"
                      fill="pink"
                    >
                      <path
                        d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"
                      />
                    </svg>
                    <h3
                      class="text-left text-gray-500 card-title text-md dark:text-white"
                    >
                      <span class="font-black" v-text="me.likes"></span>
                      <span> Likes</span>
                    </h3>
                    <!-- views count block -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6 ml-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path
                        fill-rule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <h2
                      class="text-left text-gray-500 card-title text-md dark:text-white"
                    >
                      <span class="font-black" v-text="me.visits"></span>
                      <span> Views</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- video section for those with video -->
          <div>
            <!-- This is an example component -->
            <div>
              <div
                class="flex w-full max-w-sm mx-auto overflow-hidden bg-white dark:bg-gray-700 rounded-lg shadow-md"
              >
                <div class="flex flex-col m-5" v-if="me.user.package_activity">
                  <div class="flex flex-row gap-20 mb-2">
                    <p
                      class="text-sm font-semi-bold font-sans dark:text-gray-50 text-left"
                    >
                      Profile Video
                    </p>
                    <input
                      @change="uploadv"
                      ref="videoInput"
                      type="file"
                      class="hidden"
                    />

                    <button
                      class="mt-4 btn dark:text-gray-50 dark:bg-gray-500 btn-sm"
                      @click="$refs.videoInput.click()"
                    >
                      upload
                    </button>
                  </div>
                  <div class="relative">
                    <video
                      class="w-screen"
                      :key="getURL(me.user.coverVideo)"
                      ref="videoPlayer"
                      :autoplay="false"
                      controls
                    >
                      <source
                        class="w-30 h-30"
                        :src="getURL(me.user.coverVideo)"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
              <div class="px-2 mt-3 text-left uppercase">
                <ul v-if="me.user.looks">
                  <li class="font-sans font-bold dark:text-white">
                    Personal
                    <router-link to="/looks-personality"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 text-black dark:text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        /></svg
                    ></router-link>
                  </li>
                  <li
                    class="mt-4 font-sans text-sm dark:text-white"
                    v-if="me.user.looks.ethnicity"
                  >
                    Ethnicity- {{ me.user.looks.ethnicity }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.looks.body_type"
                  >
                    Body Type: {{ me.user.looks.body_type }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.looks.gender"
                  >
                    Gender: {{ me.user.looks.gender }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.looks.height"
                  >
                    Height: {{ me.user.looks.height }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.user_basic.relationship"
                  >
                    Relationship: {{ me.user.user_basic.relationship }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.user_basic.workstatus"
                  >
                    Work: {{ me.user.user_basic.workstatus }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.user_basic.education_level"
                  >
                    Education: {{ me.user.user_basic.education_level }}
                  </li>
                  <CoolLightBox :items="items" :index="index" @close="index = null">
                  </CoolLightBox>
                </ul>
                <hr class="py-2 border-gray-50" />
              </div>
              <div class="px-2 mt-3 text-left uppercase" v-if="me.user.lifeStyle">
                <ul class="mt-3">
                  <li
                    class="font-sans font-bold dark:text-white"
                    v-if="me.user.lifeStyle"
                  >
                    LIFE STYLE<router-link to="/lifestyle"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6 text-black dark:text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        /></svg
                    ></router-link>
                  </li>

                  <li
                    class="mt-4 font-sans text-sm dark:text-white"
                    v-if="me.user.lifeStyle.live_with"
                  >
                    Lives With- {{ me.user.lifeStyle.live_with }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.lifeStyle.car"
                  >
                    Car: {{ me.user.lifeStyle.car }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.lifeStyle.religion"
                  >
                    Religion: {{ me.user.lifeStyle.religion }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.lifeStyle.smoke"
                  >
                    Smoke: {{ me.user.lifeStyle.smoke }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.lifeStyle.drinking"
                  >
                    Drink: {{ me.user.lifeStyle.drinking }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.lifeStyle.travel"
                  >
                    Travel: {{ me.user.lifeStyle.travel }}
                  </li>
                  <li
                    class="mt-3 font-sans text-sm dark:text-white"
                    v-if="me.user.user_basic.education_level"
                  >
                    Education: {{ me.user.user_basic.education_level }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- end of video section -->
        </div>
      </div>
      <!-- send section start -->
      <div class="col-span-7 -mt-5 sm:mx-5 lg:mx-0 md:mx-5">
        <div class="col-span-3 bordered dark:bg-gray-800">
          <!-- profile name and basic section -->
          <div class="hidden mt-5 rounded-none shadow-none card lg:block lg:mx-3">
            <div
              class="grid grid-cols-1 font-black card-body dark:bg-gray-800 lg:grid-cols-2 sm:grid-cols-1"
            >
              <div>
                <h1 class="text-4xl text-left dark:text-white">
                  <span class="font-sans text-2xl font-bold text-pink-500 uppercase">
                    {{ me.user.base_profile.firstName }}
                    {{ me.user.base_profile.lastName }}</span
                  ><router-link to="/general-settings"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 text-pink-500 dark:text-white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      /></svg
                  ></router-link>

                  <p
                    style="font-size: 15px"
                    class="pt-5 font-sans font-normal break-all text-left dark:text-gray-200"
                    v-if="me.user.user_basic"
                  >
                    <span> Bio:</span>

                    {{ me.user.user_basic.bio }}
                  </p>
                </h1>
              </div>
              <div class="card-actions dark:text-white">
                <!-- likes count block -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="font-sans w-7 h-7"
                  viewBox="0 0 20 20"
                  fill="pink"
                >
                  <path
                    d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"
                  />
                </svg>
                <h3 class="text-left text-gray-500 card-title text-md dark:text-white">
                  <span class="font-black" v-text="me.likes"></span>
                  <span> Likes</span>
                </h3>
                <!-- views count block -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 ml-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  <path
                    fill-rule="evenodd"
                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                <h2 class="text-left text-gray-500 card-title text-md dark:text-white">
                  <span class="font-black" v-text="me.visits"></span>
                  <span> Views</span>
                </h2>
              </div>
            </div>
          </div>
          <!-- basic profile info section end -->
          <section class="text-gray-600 body-font -mt-16">
            <div class="container px-5 py-24 mx-auto">
              <p class="px-5 font-bold text-left mb-5 text-gray-500">
                Popular Flirttool Users
              </p>
              <div class="flex flex-wrap -m-4 dark:bg-gray-700 shadow-lg">
                <div class="p-4 lg:w-1/4 md:w-1/2">
                  <div class="h-full flex flex-col items-center text-center">
                    <a
                      target="_blank"
                      href="https://flirttool.com/@GabbyBaby316"
                      class="rounded-full"
                    >
                      <img
                        alt="team"
                        class="object-center object-cover rounded-full h-24 w-24 border-2 border-pink-500"
                        src="https://flirttool.com/media/upload/f_pics/52587307_1.jpg"
                    /></a>
                  </div>
                </div>
                <div class="p-4 lg:w-1/4 md:w-1/2">
                  <div class="h-full flex flex-col items-center text-center">
                    <a
                      target="_blank"
                      href="https://flirttool.com/@SabrinaH24"
                      class="rounded-full"
                    >
                      <img
                        alt="team"
                        class="object-center object-cover rounded-full h-24 w-24 border-2 border-pink-500"
                        src="https://flirttool.com/media/upload/f_pics/52810947_1.jpg"
                    /></a>
                  </div>
                </div>
                <div class="p-4 lg:w-1/4 md:w-1/2">
                  <div class="h-full flex flex-col items-center text-center">
                    <a target="_blank" href="https://flirttool.com/@DungonDad"
                      ><img
                        alt="team"
                        class="object-center object-cover rounded-full h-24 w-24 border-2 border-pink-500"
                        src="https://flirttool.com/media/upload/trans_pics/9830777_0.jpg"
                    /></a>
                  </div>
                </div>
                <div class="p-4 lg:w-1/4 md:w-1/2">
                  <div class="h-full flex flex-col items-center text-center">
                    <a target="_blank" href="https://flirttool.com/@jessikacay99">
                      <img
                        alt="team"
                        class="object-center object-cover rounded-full h-24 w-24 border-2 border-pink-500"
                        src="https://flirttool.com/media/upload/f_pics/52779689_1.jpg"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- Profile pics section -->
          <div class="flex justify-end ...">
            <button
              class="mx-3 mt-5 mb-2 btn btn-sm font-sans text-white hover:bg-pink-300 bg-pink-500 dark:text-gray-200"
              @click="$refs.multPro.click()"
            >
              Add Pics
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6 mx-2 mb-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </button>
            <input
              @change="multipleUpload"
              multiple
              class="hidden"
              ref="multPro"
              type="file"
            />
          </div>
          <div class="h-full mt-5 rounded-none shadow-md card lg:mx-3 dark:bg-gray-800">
            <div
              class="overflow-hidden bg-white rounded-md min-h-80 aspect-w- aspect-h-1 dark:bg-gray-700 lg:aspect-none lg:h-full"
            >
              <figure>
                <img
                  class="w-20 h-12 mt-1 border-2 rounded-none shadow-xl cursor-pointer elem"
                  v-for="(pic, idx) in me.user.photos"
                  :key="idx"
                  @click="slideClicked(idx)"
                  :src="getURL(pic.image)"
                  :style="pic.unsafe ? 'filter: blur(14px);' : ''"
                  style="width: 150px; height: 150px"
                />
                <!-- src="https://picsum.photos/id/1005/400/250"  -->
              </figure>
            </div>
          </div>

          <!-- user profile looks -->
          <div class="hidden mt-5 rounded-none shadow-md card lg:mx-3 dark:bg-gray-800">
            <h1 class="px-5 pt-3 text-2xl font-bold text-left dark:text-gray-200">
              Looks
            </h1>
            <div class="card-actions lg:flex-row">
              <div
                v-if="me.user.looks"
                class="grid grid-cols-1 gap-10 mb-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-2"
              >
                <div v-if="me.user.looks.ethnicity">
                  <p
                    class="px-5 font-sans text-lg font-semibold text-left dark:text-white"
                  >
                    ethnicity
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.looks.ethnicity }}
                  </p>
                </div>

                <div v-if="me.user.looks.body_type">
                  <p class="px-5 font-sans text-lg font-black text-left dark:text-white">
                    Body Type
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.looks.body_type }}
                  </p>
                </div>

                <div v-if="me.user.looks.gender">
                  <p class="px-5 font-sans text-lg font-black text-left dark:text-white">
                    Gender
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.looks.gender }}
                  </p>
                </div>
                <div v-if="me.user.looks.height">
                  <p class="px-5 font-sans text-lg font-black text-left dark:text-white">
                    Height
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.looks.height }}
                  </p>
                </div>
              </div>

              <CoolLightBox :items="items" :index="index" @close="index = null">
              </CoolLightBox>
            </div>
          </div>
          <!-- user profile looks -->

          <!-- basic info card start -->
          <div
            class="hidden mt-5 rounded-none shadow-md card lg:mx-3 dark:bg-gray-800"
            v-if="me.user.user_basic"
          >
            <h1 class="px-5 pt-3 text-2xl font-bold text-left dark:text-gray-300">
              Basic Information
            </h1>
            <div class="card-actions lg:flex-row">
              <div
                class="grid grid-cols-1 gap-10 mb-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-2"
              >
                <div v-if="me.user.user_basic.relationship">
                  <p
                    class="px-5 font-sans text-lg font-semibold text-left dark:text-white"
                  >
                    Relationship
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.user_basic.relationship }}
                  </p>
                </div>

                <div v-if="me.user.user_basic.workstatus">
                  <p class="px-5 font-sans text-lg font-black text-left dark:text-white">
                    Work Status
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.user_basic.workstatus }}
                  </p>
                </div>

                <div v-if="me.user.user_basic.education_level">
                  <p class="px-5 font-sans text-lg font-black text-left dark:text-white">
                    Education Level
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.user_basic.education_level }}
                  </p>
                </div>
              </div>

              <CoolLightBox :items="items" :index="index" @close="index = null">
              </CoolLightBox>
            </div>
          </div>
          <!-- basic info card end -->

          <!-- lifeStyle -->
          <div
            class="hidden mt-5 rounded-none shadow-md card lg:mx-3 dark:bg-gray-800"
            v-if="me.user.lifeStyle"
          >
            <h1 class="px-5 pt-3 text-2xl font-bold text-left dark:text-gray-300">
              Life Style
            </h1>

            <div class="card-actions lg:flex-row">
              <div
                class="grid grid-cols-1 gap-10 mb-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-2"
              >
                <div v-if="me.user.lifeStyle.live_with">
                  <p
                    class="px-5 font-sans text-lg font-semibold text-left dark:text-white"
                  >
                    Live With
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.lifeStyle.live_with }}
                  </p>
                </div>

                <div v-if="me.user.lifeStyle.car">
                  <p class="px-5 font-sans text-lg font-black text-left dark:text-white">
                    Car
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.lifeStyle.car }}
                  </p>
                </div>

                <div v-if="me.user.lifeStyle.religion">
                  <p class="px-5 font-sans text-lg font-black text-left dark:text-white">
                    Religion
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.lifeStyle.religion }}
                  </p>
                </div>

                <div v-if="me.user.lifeStyle.smoke">
                  <p class="px-5 font-sans text-lg font-black text-left dark:text-white">
                    Smoke
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.lifeStyle.smoke }}
                  </p>
                </div>
                <div v-if="me.user.lifeStyle.drinking">
                  <p class="px-5 font-sans text-lg font-black text-left dark:text-white">
                    Drink
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.lifeStyle.drinking }}
                  </p>
                </div>

                <div v-if="me.user.lifeStyle.travel">
                  <p class="px-5 font-sans text-lg font-black text-left dark:text-white">
                    Travel
                  </p>
                  <p class="px-5 pt-2 text-xl text-left dark:text-gray-300">
                    {{ me.user.lifeStyle.travel }}
                  </p>
                </div>
              </div>

              <CoolLightBox :items="items" :index="index" @close="index = null">
              </CoolLightBox>
            </div>
          </div>

          <!-- lifeStyle -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getURL, imageAverageColor } from "../../utils/helpers";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import settingsSelectors from '../../components/settingsSelectors.vue';
// import GeneralSettings from "../../components/generalSettings.vue";
import { DEFAULT_SETTINGS } from '../../queries/auth';
import {
  LOGGEDIN_DATA,
  MULTIPLE_UPLOAD,
  PROFILE_PIC_UPDATE,
  UPDATE_PROFILE_COVER_VIDEO,
} from "../../queries/auth";
import { lightOrDark } from "../../utils/helpers";
export default {
  data: () => ({
    me: null,
    items: [],
    index: null,
    blendColor: "",
    allUserPics: [],
    loadingProfile: true,
    loadingContent: true,
    defaultSettings: {}
  }),
  
  apollo: {
    me: {
      query: LOGGEDIN_DATA,
      variables() {
        return {
          addr: this.$store.state.core.addr,
        };
      },
      fetchPolicy: "network-only",
      result({ data: { me } }) {
        // console.clear()
        // console.log(data)
        this.loadingProfile = false;
        return me;
      },
    },
  },
  components: { CoolLightBox , settingsSelectors,DEFAULT_SETTINGS },
  created() {
    this.$apollo.queries.me.refetch().finally(() => {
      this.loadingContent = false;
    });
    if (this.me) {
      const allPics = this.me.user.photos;
      allPics.forEach((e) => {
        this.allUserPics.push(getURL(e.image));
      });
      imageAverageColor(getURL(this.me.user.profilePic)).then((data) => {
        const imgData = data;
        this.blendColor = `rgba(${imgData[0]},${imgData[1]},${imgData[2]})`;
      });
    }
  },
  methods: {
    getURL,
    fetchProfileData() {
      this.$apollo.query({
        query: LOGGEDIN_DATA,DEFAULT_SETTINGS,
			}).then(({ data }) => {
				this.defaultSettings = data.userSettingsData.user;
			}).catch(e => {
				console.error(e)
			})

      
    },
    lightOrDark,
    slideClicked(idx) {
      console.clear();
      console.log(idx, "IDX");
      this.index = idx;
      console.log(this.allUserPics, "Dawg");
      this.items = this.allUserPics.filter((obj) => {
        return obj.unsafe !== true;
      });
      this.items = this.me.user.photos.map((e) => getURL(e.image));
      console.warn(this.me.user.photos, "items");
      // this.items = [
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // 	"https://picsum.photos/id/1005/400/250",
      // ];
    },
    profileClicked() {
      this.items = [getURL(this.me.user.profilePic)];
      this.index = 0;
    },
    uploadProfilePic() {
      this.$refs.profileInput.click();
      console.log();
    },
    // files positional arg
    uploadv({ target: { files = [] } }) {
      if (!files.length) {
        return;
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_PROFILE_COVER_VIDEO,
          variables: {
            file: files[0],
          },
        })
        .then(({ data }) => {
          this.me.user.coverVideo = data.updateProfileCoverVideo;
        });
    },
    // Handle profile pic upload
    upload({ target: { files = [] } }) {
      if (!files.length) {
        return;
      }
      this.$apollo
        .mutate({
          mutation: PROFILE_PIC_UPDATE,
          variables: {
            file: files[0],
          },
        })
        .then(({ data }) => {
          const newProfile = data.updateProfilePic;

          let user = this.$store.state.core.user;
          user.profilePic = newProfile;
          this.$store.commit("setUserData", user);
          this.me.user.profilePic = newProfile;
          imageAverageColor(getURL(newProfile)).then((data) => {
            const imgData = data;
            this.blendColor = `rgba(${imgData[0]},${imgData[1]},${imgData[2]})`;
          });
          console.warn(data);
        });
    },
    multipleUpload({ target: { files = [] } }) {
      var reader = new FileReader();
      if (!files.length) {
        return;
      }
      reader.addEventListener(
        "load",
        function () {
          var avatarImg = new Image();
          var src = reader.result;
          avatarImg.src = src;
          document.getElementById("dataUrl").innerText = src;
          avatarImg.onload = function () {
            var c = document.getElementById("myCanvas");
            var ctx = c.getContext("2d");
            ctx.canvas.width = avatarImg.width;
            ctx.canvas.height = avatarImg.height;
            ctx.drawImage(avatarImg, 0, 0);
          };
        },
        false
      );
      files.forEach((fl) => {
        const dataUrl = reader.readAsDataURL(fl);
        this.me.user.photos.push({
          unsafe: false,
          image: dataUrl,
          newx: true,
        });
      });
      this.$apollo
        .mutate({
          mutation: MULTIPLE_UPLOAD,
          variables: {
            files: files,
          },
        })
        .then(({ data }) => {
          this.$apollo.queries.me.refetch();
          // this.me.user.photos = data.addProfilePics;
          console.warn(data);
        });
    },
  },
};
</script>
<style scoped>
.wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 250px;
  white-space: nowrap;
}
.elem {
  display: inline-block;
  object-fit: cover;
  width: 40%;
  height: 200px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: rgba(128, 128, 128, 0.418);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.418);
}
.profileImg {
  position: relative;
}
.profileEditbtn {
  position: absolute;
  width: 80px;
  opacity: 0.8;
  height: 40px;
  left: 50%;
  top: 50%;
  margin-left: -50px; /*half of the width */
  margin-top: -20px; /*half of the height */
}
</style>
