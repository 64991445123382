<template>
  <div class="">
    <div
      class="px-1 py-0 mx-auto  sm:max-w-xl md:max-w-full border-0 bg-white    lg:max-w-screen-xl md:px-5 lg:px-8 fixed top-0 left-0 right-0  z-10">
      <!-- mobile -->
      <div class="navbar bg-base-100 lg:hidden">
        <div class="navbar-start">
          <div class="dropdown">
            <label tabindex="0" class="btn btn-ghost btn-circle shadow-xl  border-0">
              <svg xmlns="http://www.w3.org/2000/svg" fill="gold" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
              </svg>
            </label>
            <ul tabindex="0"
              class="menu menu-sm dropdown-content mt-3 z-[1] p-2  shadow-lg border-2  bg-white rounded-box w-52">
              <li><a> <span class="text-left text-sm bg-pink-500 px-3 py-2 rounded-md font-sans text-white"
                    v-if="!$store.state.core.user.used_hpass && $store.state.core.user.profileSetupStep == 3"
                    @click="startHourlyPackage"> Start Free Chat </span>
                  <div class="">
                    <countdown v-if="$store.state.core.user.has_hpass ||
                      $store.state.core.user.package_activity
                      " ref="countdown" :time="time" :transform="transform" :emit-events="false">
                      <template slot-scope="props">
                        <div class="grid w-full grid-cols-1 pl-0 mt-0 border-0"
                          v-if="$store.state.core.user.package_activity">
                          <P class="text-left px-3 font-sans text-2xs">Time left</P>
                          <div v-if="$store.state.core.user.package_activity.active"
                            class="px-2 py-1 font-sans text-left text-xl text-pink-600 bg-gray-50 countdown dark:text-pink-600 dark:bg-gray-700 bordered">
                            <span :style="`--value: ${props.days}`"></span>:
                            <span :style="`--value: ${props.hours}`"></span>:
                            <span :style="`--value: ${props.minutes}`"></span>:

                            <span :style="`--value: ${props.seconds}`"></span>
                          </div>
                        </div>
                        <div class="grid w-full grid-cols-1 pl-3 mt-2" v-else-if="$store.state.core.user.has_hpass">
                          <P class="text-left px-2 text-2xs">Time left</P>
                          <div
                            class="px-2 py-0 font-sans text-xl text-gray-700 bg-gray-50 countdown dark:text-pink-600 dark:bg-gray-700 bordered">
                            <span :style="`--value: ${props.days}`"></span>:
                            <span :style="`--value: ${props.hours}`"></span>:
                            <span :style="`--value: ${props.minutes}`"></span>:

                            <span :style="`--value: ${props.seconds}`"></span>
                          </div>
                        </div>
                      </template>
                    </countdown>
                  </div>
                </a></li>


                <li> <button @click="logout" class="flex items-center w-full p-1 text-white  hover:bg-pink-600">
                  <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="purple"
                    viewBox="0 0 24 24" class="w-6 h-6 mr-2">
                    <path
                      d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z">
                    </path>
                  </svg>
                  <span class="text-purple-900">Logout</span>
                </button></li>
              <!-- <li> <div class="fixed bottom-0 w-full">
            <button @click="logout" class="flex items-center w-full p-4 text-white bg-pink-500 hover:bg-purple-600">
              <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor"
                viewBox="0 0 24 24" class="w-6 h-6 mr-2">
                <path
                  d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z">
                </path>
              </svg>
              <span>Logouts</span>
            </button>
          </div> -->
              <div class="fixed bottom-0 w-full">
               
              </div>
             
              <!-- <li><a>Portfolio</a></li>
        <li><a>About</a></li> -->
            </ul>
          </div>
        </div>
        <div class="navbar-center">
          <img :src="getCommunityMeta.logo" class="h-10" alt="">
        </div>
        <div class="navbar-end">
          <button class="btn btn-ghost btn-circle">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </button>
          <button class="border-none btn btn-outline btn-square lg:hidden" @click="isOpen = true">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 dark:text-gray-200" viewBox="0 0 20 20"
              fill="currentColor">
              <path fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd" />
            </svg>
          </button>
          <transition enter-class="opacity-0" enter-active-class="ease-out transition-medium" enter-to-class="opacity-100"
            leave-class="opacity-100" leave-active-class="ease-out transition-medium" leave-to-class="opacity-0">
            <div @keydown.esc="isOpen = false" v-show="isOpen">
              <div @click="isOpen = false" class="absolute inset-0 h-screen bg-black opacity-50" tabindex="0"></div>
            </div>
          </transition>
        </div>
      </div>
      <!-- mobile -->
      <div class="relative flex py-5 items-start justify-between lg:block hidden">
        <a @click="$router.push('/find-matches')" class="cursor-pointer">
          <img class="float-left h-16" :src="getCommunityMeta.logo" />
        </a>
        <ul class="flex items-right justify-end py-0   lg:flex">
          <!-- <li><a href="/" aria-label="Our product" title="Our product" class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400">Product</a></li> -->
          <button v-if="!($mq === 'sm') && freeChatsUsed"
            class="invisible mx-2 bg-purple-100 md:visible btn border-0 btn-outline btn-md btn-accent hover:bg-purple-700">
            <a style="color: #dc4b78" class="dark:text-white">
              Use your free chats
            </a>
          </button>
          <button v-if="this.$store.getters.showFlirton" @click="$router.push('/flirtons')" class="
					btn 
					mx-2
					bg-white
					btn-accent
					hover:bg-purple-200					
					dark:hover:bg-gray-800
					text-pink-100
					
				"><img src="@/assets/co-je-mrozi-to-je-bozi-lovers.gif" class="h-7 w-9">
            <p class="text-sm pt-1 px-7 font-sans text-pink-600">
              Get Flirtons
            </p>

          </button>
          <button @click="$router.push('/flirts')"
            class="mx-2 text-pink-100 btn hidden lg:block bg-pink-500 btn-accent btn glass hover:bg-purple-200 dark:hover:bg-gray-800">
            <p class="pt-1 font-sans font-bold text-lg text-pink-50 px-7">
              +Flirts
            </p>
          </button>
         <button @click="$store.commit('setCurrentLang', null)"
            class="mx-2 text-pink-100 btn hidden lg:block bg-pink-500 btn-accent btn glass hover:bg-purple-200 dark:hover:bg-gray-800">
            <p class="pt-1 font-sans font-bold text-lg text-pink-50 px-7">
              Language
            </p>
          </button>
          <!-- <button
        @click="$router.push('/valentine')"
        class="mx-2 text-pink-100 btn bg-pink-500 btn-accent hover:bg-purple-200 dark:hover:bg-gray-800"
      >
        <p class="pt-1 font-sans font-bold text-lg text-pink-50 px-7">
          Valentine special
        </p>
      </button> -->
          <button v-if="!$store.state.core.user.used_hpass && $store.state.core.user.profileSetupStep == 3"
            @click="startHourlyPackage"
            class="mx-2 text-pink-100 btn font-sans  hidden lg:block btn glass bg-pink-500 btn-accent hover:bg-purple-200 dark:hover:bg-gray-800">
            <p class="pt-1 font-sans font-bold   text-pink-50 px-7">
              Start hourly package
            </p>
          </button>

          <div class="mt-0">
            <button @click="$router.push('/flirts')" v-if="!($mq === 'sm')"
              class="mx-0 text-white shadow-sm btn btn-md bg-purple-100 btn-accent  hover:bg-purple-200 dark:hover:bg-gray-800">
              <img class="w-7 h-7" :src="require('@/assets/kisspng.png')" />
              <p class="px-5 pt-1 font-sans font-bold text-lg text-pink-500">
                {{ $store.state.core.balance }}
              </p>
              <!-- <p > {{ this.$store.commit("setBalance", credit.balance + credit.flirtons)}}</p> -->
            </button>
          </div>

          <div class="hidden lg:block">
            <countdown v-if="$store.state.core.user.has_hpass ||
              $store.state.core.user.package_activity
              " ref="countdown" :time="time" :transform="transform" :emit-events="false">
              <template slot-scope="props">
                <div class="grid w-full grid-cols-1 pl-0 mt-0 border-0" v-if="$store.state.core.user.package_activity">
                  <P class="text-left px-3 font-sans text-2xs">Time left</P>
                  <div v-if="$store.state.core.user.package_activity.active"
                    class="px-2 py-1 font-sans text-left text-xl text-pink-600 bg-gray-50 countdown dark:text-pink-600 dark:bg-gray-700 bordered">
                    <span :style="`--value: ${props.days}`"></span>:
                    <span :style="`--value: ${props.hours}`"></span>:
                    <span :style="`--value: ${props.minutes}`"></span>:

                    <span :style="`--value: ${props.seconds}`"></span>
                  </div>
                </div>

                <div class="grid w-full grid-cols-1 pl-3 mt-2" v-else-if="$store.state.core.user.has_hpass">
                  <P class="text-left px-2 text-2xs">Time left</P>
                  <div
                    class="px-2 py-0 font-sans text-xl text-gray-700 bg-gray-50 countdown dark:text-pink-600 dark:bg-gray-700 bordered">
                    <span :style="`--value: ${props.days}`"></span>:
                    <span :style="`--value: ${props.hours}`"></span>:
                    <span :style="`--value: ${props.minutes}`"></span>:

                    <span :style="`--value: ${props.seconds}`"></span>
                  </div>
                </div>
              </template>
            </countdown>
          </div>
        </ul>

        <div v-if="flirtsRenewTime > 0 && $store.state.core.user.activePackage.packageType == 'infny'"
          class="flex gap-0.5 border-4 shadow-inner rounded-lg ml-4">
          <div class="mt-0">
            <button v-if="!($mq === 'sm')"
              class="mx-0 text-white shadow-sm btn btn-md bg-purple-100 btn-accent hover:bg-purple-200 dark:hover:bg-gray-800">
              <img class="w-7 h-7" :src="require('@/assets/Infinity12344.png')" />
              <!-- <p class="px-5 pt-1 font-sans font-bold text-lg text-pink-500">
              {{ $store.state.core.balance }}
            </p> -->
            </button>
          </div>
          <div class="hidden lg:block">
            <countdown v-if="flirtsRenewTime > 0" ref="countdown" :time="flirtsRenewTime" :transform="transform"
              :emit-events="false">
              <template slot-scope="props">
                <div class="grid w-full grid-cols-1 pl-0 mt-0 border-0">
                  <P class="text-left px-3 font-sans text-2xs">{{ infinityTimerMessage }}</P>
                  <div
                    class="px-2 py-1 font-sans text-left text-xl text-pink-600 bg-gray-50 countdown dark:text-pink-600 dark:bg-gray-700 bordered">
                    <span :style="`--value: ${props.days}`"></span>:
                    <span :style="`--value: ${props.hours}`"></span>:
                    <span :style="`--value: ${props.minutes}`"></span>:

                    <span :style="`--value: ${props.seconds}`"></span>
                  </div>
                </div>
              </template>
            </countdown>
          </div>
        </div>
      </div>
      <div class=" lg:hidden">
        <aside
          class="fixed top-0 left-0 z-10 w-64 h-full  transition-all duration-300 ease-in-out transform bg-white dark:bg-gray-800"
          :class="isOpen ? 'translate-x-0' : '-translate-x-full'">
          <span @click="isOpen = false" class="flex items-start w-full p-4 border-b">
            <img :src="getCommunityMeta.logo" class="h-10" alt="">
          </span>
          <!-- user detail block start -->
          <div class="w-full px-3 text-left border-b">
            <p class="pt-2 text-base font-semibold dark:text-white">
              {{ $store.state.core.user.base_profile.firstName }}
              {{ $store.state.core.user.base_profile.lastName }}
            </p>
            <button class="flex items-center justify-between w-full focus:outline-none">
              <p class="text-gray-600 dark:text-white" @click="$router.push('/profile')">
                @{{ $store.state.core.user.username }}
              </p>

              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6"
                :class="{ 'text-gray-200': $store.state.core.darkTheme }" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" v-html="$store.state.core.darkTheme ? darkSvg : lightSvg"
                @click="setTheme($store.state.core.darkTheme)">
                <path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
							/>
              </svg> -->
              <!-- <img
							:src="
								$store.state.core.darkTheme
									? 'https://s2.svgbox.net/octicons.svg?ic=sun'
									: 'https://s2.svgbox.net/hero-outline.svg?ic=moon'
							"
							class="w-6 h-6"
						/> -->
            </button>
            <div class="h-2"></div>
          </div>
          <!-- user detail block end -->
          <span v-for="(item, idx) in drawerItems" :key="idx" @click="changeRoute(item)"
            class="flex items-center p-3 cursor-pointer hover:bg-pink-500 hover:text-white"><span class="mr-2">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                viewBox="0 0 24 24" class="w-6 h-6 dark:text-gray-200" v-html="item.svg"></svg>
            </span>
            <span class="dark:text-gray-200">{{ item.text }}</span></span>
          <div class="fixed bottom-0 w-full">
            <button @click="logout" class="flex items-center w-full p-4 text-white bg-pink-500 hover:bg-purple-600">
              <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor"
                viewBox="0 0 24 24" class="w-6 h-6 mr-2">
                <path
                  d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z">
                </path>
              </svg>
              <span>Logout</span>
            </button>
          </div>
          <div class="fixed bottom-0 w-full">
            <button @click="logout" class="flex items-center w-full p-4 text-white bg-pink-500 hover:bg-purple-600">
              <svg fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor"
                viewBox="0 0 24 24" class="w-6 h-6 mr-2">
                <path
                  d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z">
                </path>
              </svg>
              <span>Logout</span>
            </button>
          </div>
        </aside>
        <!-- end of aside drawer -->
      </div>
    </div>
    <!-- secondnav bar -->
    <div class=" mt-24 lg:block hidden">
      <div class="px-4 py-2 bg-pink-600   mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div class="relative flex items-center justify-between">
          <div class="flex items-center">
            <!-- <a href="/" aria-label="Company" title="Company" class="inline-flex items-center mr-8">
              <svg class="w-8 text-teal-accent-400" viewBox="0 0 24 24" stroke-linejoin="round" stroke-width="2"
                stroke-linecap="round" stroke-miterlimit="10" stroke="currentColor" fill="none">
                <rect x="3" y="1" width="7" height="12"></rect>
                <rect x="3" y="17" width="7" height="6"></rect>
                <rect x="14" y="1" width="7" height="6"></rect>
                <rect x="14" y="11" width="7" height="12"></rect>
              </svg>
              <span class="ml-2 text-xl font-bold tracking-wide text-gray-100 uppercase">Company</span>
            </a> -->
            <button v-if="!$store.state.core.user.used_hpass && $store.state.core.user.profileSetupStep == 3"
              @click="startHourlyPackage"
              class="mx-2 text-pink-100 btn font-sans lg:hidden   bg-pink-500  btn-accent hover:bg-purple-200 dark:hover:bg-gray-800">
              <p class="pt-1 font-sans font-bold   text-pink-50 px-7">
                Start hourly package
              </p>
            </button>
            <ul class="flex items-center hidden space-x-8 lg:flex">
              <a href="/"
                class="flex aspect-square min-h-[32px] w-16 glass flex-col glass items-center justify-center gap-1 rounded-md p-1.5 bg-indigo-50 text-pink-600 dark:bg-sky-900 dark:text-sky-50">
                <!-- HeroIcon - User -->
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                </svg>
                <small class="text-center text-xs font-medium font-sans"> Home </small>
              </a>
              <router-link to="/likes">
              <a href=""
                class="flex aspect-square min-h-[32px] w-16 glass flex-col items-center justify-center gap-1 rounded-md p-1.5 text-gray-50 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-slate-800">
                <!-- HeroIcon - Chart Bar -->
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                </svg>
                <small class="text-center text-xs font-sans font-medium"> {{ $t('likes') }}  </small>
              </a></router-link>
             <router-link to="/matches"> <a href=""
                class="flex aspect-square glass min-h-[32px] w-16 flex-col items-center justify-center gap-1 rounded-md p-1.5 text-gray-50 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-slate-800">
                <!-- HeroIcon - Cog-6-tooth -->
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                </svg>
                <small class="text-center text-xs font-medium  font-sans">{{ $t('matches') }}</small>
              </a></router-link>
             <router-link to="/liked"> <a href=""
                class="flex aspect-square min-h-[32px] w-16 glass flex-col items-center justify-center gap-1 rounded-md p-1.5 text-gray-50 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-slate-800">
                <!-- HeroIcon - Cog-6-tooth -->
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                </svg>
                <small class="text-center text-xs font-medium  font-sans"> {{$t('Liked')}} </small>
              </a></router-link>
             <!-- <router-link to="/dislikes"> <a href=""
                class="flex aspect-square min-h-[32px] w-16 glass flex-col items-center justify-center gap-1 rounded-md p-1.5 text-gray-50 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-slate-800">
           
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384" />
                </svg>
            <small class="text-center text-xs font-sans font-medium"> {{ $t('Disliked') }} </small>
              </a></router-link> -->
            </ul>
          </div>
          <!-- <ul class="flex items-center hidden space-x-8 lg:flex">
            <li><a href="/" aria-label="Sign in" title="Sign in"
                class="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400">Sign
                in</a></li>
            <li>
              <a href="/"
                class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                aria-label="Sign up" title="Sign up">
                Sign up
              </a>
            </li>
          </ul> -->
          <!-- Mobile menu -->
          <div class=" flex flex-wrap gap-5">
            <router-link to="/chat"> <button class="mt-5 lg:block hidden hover:bg-white"><svg
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
                  class="w-10 h-10">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>
              </button></router-link>
            <button>
              <li>
                <!-- <notification-drop-down /> -->
                <div v-if="$mq === 'lg'"
                  class="flex items-center -mt-5 justify-center sm:hidden border-0  md:block lg:block">
                  <notification-drop-down />
                </div>
                <button @click="$router.push('/notification')"
                  class="bg-purple-200 lg:hidden btn border-0 btn-circle btn-md">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-7" viewBox="0 0 20 20" fill="#dc4b78">
                    <path
                      d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
                  </svg>
                </button>
                <span v-if="$store.state.core.notifiationCount > 0"
                  class="absolute inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full top-3 right-3">{{
                    $store.state.core.notifiationCount }}</span>
              </li>
            </button> <button v-if="$mq === 'lg'" @click="isOpen = true" aria-label="Open Menu" title="Open Menu"
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline">
              <div v-if="$mq === 'lg'" class="flex items-center justify-center  md:block lg:block">
                <dropdown ref="ndrop"></dropdown>
              </div>
            </button>
            <!-- Mobile menu dropdown
        <div class="absolute top-0 left-0 w-full">
          <div class="p-5 bg-white border rounded shadow-sm">
            <div class="flex items-center justify-between mb-4">
              <div>
                <a href="/" aria-label="Company" title="Company" class="inline-flex items-center">
                  <svg class="w-8 text-deep-purple-accent-400" viewBox="0 0 24 24" stroke-linejoin="round" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" stroke="currentColor" fill="none">
                    <rect x="3" y="1" width="7" height="12"></rect>
                    <rect x="3" y="17" width="7" height="6"></rect>
                    <rect x="14" y="1" width="7" height="6"></rect>
                    <rect x="14" y="11" width="7" height="12"></rect>
                  </svg>
                  <span class="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">Company</span>
                </a>
              </div>
              <div>
                <button aria-label="Close Menu" title="Close Menu" class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                  <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <nav>
              <ul class="space-y-4">
                <li><a href="/" aria-label="Our product" title="Our product" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Product</a></li>
                <li><a href="/" aria-label="Our product" title="Our product" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Features</a></li>
                <li><a href="/" aria-label="Product pricing" title="Product pricing" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Pricing</a></li>
                <li><a href="/" aria-label="About us" title="About us" class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">About us</a></li>
                <li>
                  <a
                    href="/"
                    class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                    aria-label="Sign up"
                    title="Sign up"
                  >
                    Sign up
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        -->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dropdown from "./dropdown.vue";
import { logout } from "@/utils/basicAuth";
import NotificationDropDown from "./notificationDropDown.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { UDPATE_PASS_TIME, START_PASS } from "@/queries/auth";
import iconnavbar from './purple/iconnavbar.vue'
import { getCommunityMeta } from "@/utils/helpers.js";

export default {
  components: {
    dropdown,
    NotificationDropDown,
    countdown: VueCountdown,
    iconnavbar,
  },
  name: "navBar",
  props: {
    isOpen: Boolean,
  },
  computed: {
    getUserData() {
      return this.$store.getters.getUserData;
    },
  },
  watch: {
    getUserData(value) {
      if (value) this.setupPassDate();
      if (this.$store.state.core.user.package_activity)
        this.setPackagePassDate();
      this.setInfinityPackageRenewTime()
    },
  },
  created() {
    if (this.$store.state.core.user.has_hpass) this.setupPassDate();
    else if (this.$store.state.core.user.package_activity)
      this.setPackagePassDate();
    this.setInfinityPackageRenewTime()
  },
  data: () => ({
    getCommunityMeta,
    flirtsRenewTime: 0,
    infinityTimerMessage: "",
    time: 0,
    // time: 4 * 60 * 60 * 1000,
    freeChatsUsed: false,
    drawerItems: [
      {
        to: "/find-matches",
        text: "Find Matches",
        svg: `<path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>`,
      },
      {
        to: "/matches",
        text: "Matches",
        svg: `  <path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />`,
      },
      {
        to: "/visitors",
        text: "Visitors",
        svg: `
          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
          <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />`,
      },
      {
        to: "/flirts",
        text: "Flirts",
        svg: `
                  <path d="M3 12v3c0 1.657 3.134 3 7 3s7-1.343 7-3v-3c0 1.657-3.134 3-7 3s-7-1.343-7-3z" />
                  <path d="M3 7v3c0 1.657 3.134 3 7 3s7-1.343 7-3V7c0 1.657-3.134 3-7 3S3 8.657 3 7z" />
                  <path d="M17 5c0 1.657-3.134 3-7 3S3 6.657 3 5s3.134-3 7-3 7 1.343 7 3z" />`,
      },
      {
        to: "/chat-list",
        text: "Chats",
        svg: `  <path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" />`,
      },
      {
        to: "/likes",
        text: "Likes",
        svg: `<path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />`,
      },
      {
        to: "/affilate",
        text: "Affiliate / IKM",
        svg: ` <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />`,
      },
      {
        to: "/liked",
        text: "Liked Users",
        svg: `          <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />`,
      },
      {
        to: "/dislikes",
        text: "People i disliked",
        svg: `<path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />`,
      },
      //       {
      //         spa: false,
      //         to: "/dislikes",
      //         text: "Support",
      //         svg: `<path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
      // `,
      //       },
    ],
    lightSvg: `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
              </svg>`,
    darkSvg: `<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>`,
  }),
  methods: {
    changeLang($event) {
      this.$store.commit("setCurrentLang", $event.target.value)
      this.$i18n.locale = $event.target.value
    },
    logout,
    setInfinityPackageRenewTime() {
      let activePackage = this.$store.state.core.user.activePackage
      if (activePackage) {
        if (activePackage.packageType == 'infny') {
          let timeToTaiwanMidnight = this.timeToTaiwanMidnight()
          this.flirtsRenewTime = timeToTaiwanMidnight;
          var d = new Date(new Date().toLocaleString('en', { timeZone: 'Asia/Taipei' }))
          let packageExpireTime = new Date(this.$store.state.core.user.activePackageExpireTimestamp)
          var diff = (packageExpireTime.getTime() - d.getTime()) / 3600000;
          if (diff < 24) {
            this.infinityTimerMessage = 'Package expires in'
          } else {
            this.infinityTimerMessage = '1000 flirts in'
          }
        }
      }
    },
    setPackagePassDate() {
      const pact = this.$store.state.core.user.package_activity;
      const currentPackage =
        this.$store.state.core.user.package_activity.package;
      // start of the package
      // const startDate = new Date(currentPackage.startDate).packageStart
      console.log(currentPackage, "Cur Pack");

      if (currentPackage.timeValue > 0) {
        console.warn("ASdf");
        // const packageEnd = new Date(currentPackage.packageEndStamp)
        const currentStamp = new Date();
        let refStamp = new Date();

        const actStartStmp = new Date(pact.start_stamp);
        if (actStartStmp > currentStamp) {
          console.log(actStartStmp, currentStamp);
          const interval = actStartStmp - currentStamp;
          console.error(interval);
          refStamp.setMilliseconds(refStamp.getMilliseconds() + interval);
        }
        // refStamp.setMilliseconds(parseInt(currentPackage.timeValue)*60*60*1000)
        console.warn("...............................");
        console.warn(refStamp);
        console.warn("...............................");
        const { hours, minutes, seconds } = this.calculateInterval(
          currentStamp,
          refStamp
        );
        console.warn(hours, "hours");
        const time =
          0 * 24 * 60 * 60 * 1000 +
          hours * 60 * 60 * 1000 +
          minutes * 60 * 1000 +
          seconds * 1000;
        this.time = time;
      } else {
        const packageEnd = new Date(currentPackage.packageEnd);
        const currentStamp = new Date();
        let refStamp = new Date();
        if (packageEnd > currentStamp) {
          const interval = packageEnd - currentStamp;
          refStamp.setMilliseconds(refStamp.getMilliseconds() + interval);
        }
        console.warn(currentStamp, refStamp);
        const { days, hours, minutes, seconds } = this.calculateInterval(
          currentStamp,
          refStamp
        );
        console.warn(days, "days");
        const time =
          days * 24 * 60 * 60 * 1000 +
          hours * 60 * 60 * 1000 +
          minutes * 60 * 1000 +
          seconds * 1000;
        this.time = time;
      }
      // console.warn(days, hours, minutes, seconds)
    },
    timeToTaiwanMidnight() {
      var d = new Date(new Date().toLocaleString('en', { timeZone: 'Asia/Taipei' }))
      var h = d.getHours();
      var m = d.getMinutes();
      var s = d.getSeconds();
      var secondsUntilEndOfDate = (24 * 60 * 60) - (h * 60 * 60) - (m * 60) - s;
      return secondsUntilEndOfDate * 1000
    },
    setupPassDate() {
      if (
        this.$store.state.core.user.start_timestamp &&
        this.$store.state.core.user.has_hpass
      ) {
        const currentStamp = new Date();
        // add 4 hours here to exceed on the default limit
        const createdStamp = new Date(
          this.$store.state.core.user.start_timestamp
        );
        const { hours, minutes, seconds } = this.calculateInterval(
          currentStamp,
          createdStamp
        );
        const time =
          hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        if (currentStamp.getTime() >= createdStamp.getTime()) {
          console.clear();
          console.log("dude");
          // this.updatePassTime()
        } else {
          this.time = time;
        }
        console.error(time);
        // this.time = hours * 24 * minutes * 60 * seconds *60
      }
    },
    updatePassTime() {
      this.$apollo
        .mutate({
          mutation: UDPATE_PASS_TIME,
        })
        .then(() => {
          // update user data with a package complete state
          let uData = this.$store.state.core.user;
          uData.used_hpass = false;
          uData.has_hpass = false;
          this.$store.commit("setUserData", uData);
          this.$emit("hPassComplete");
        });
    },
    startHourlyPackage() {

      // this.loading = true;
      this.$apollo
        .mutate({
          mutation: START_PASS,
        })
        .then(({ data: { startHourlyPackage } }) => {
          this.$store.commit("setUserData", startHourlyPackage);
          this.$notify(
            {
              group: "top",
              title: "🎉 Welcome To FlirtTool 🎉",
              text: "🎉🎊 Congrats and enjoy 1 hour free chat access 🎉🎊",
              type: "success", // info, warning
            },
            8000
          );

          this.$confetti.start();
          setTimeout(() => {
            this.$confetti.stop();
          }, 3000);
        })
        .catch(() => {
          alert("err");
        })
        .finally(() => {
          this.loading = false;
        });

    },
    calculateInterval(date_future, date_now) {
      // get total seconds between the times
      var delta = Math.abs(date_future - date_now) / 1000;

      // calculate (and subtract) whole days
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      var seconds = delta % 60;
      return {
        days,
        hours,
        minutes,
        seconds,
      };
    },
    // resetCountdown() {
    // 	this.time = 5 * 60 * 60 * 1000;
    // },
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        props[key] = value < 10 ? `0${value}` : value;
      });

      return props;
    },
    dropDownClicked() {
      alert("Dropdown clicked");
    },
    setTheme(theme) {
      // const currentState = this.$store.state.darkTheme
      this.$store.commit("setTheme", !theme);
      this.isMenuOpen = false;
    },
    changeRoute(item) {
      this.isOpen = false;
      this.$router.push(item.to);
    },
  },
};
</script>
<style scoped>p {
  padding: 0px;
  display: block;
}</style>
  