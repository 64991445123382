<template>
  <div>
    <notificationGroup group="top" position="top">
      <div
        class="
          fixed
          inset-0
          z-10
          flex
          items-start
          justify-end
          p-6
          px-4
          py-6
          mt-5
          pointer-events-none
        "
      >
        <div class="w-full max-w-sm">
          <notification v-slot="{ notifications }">
            <div
              class="
                flex
                w-full
                max-w-sm
                mx-auto
                mt-4
                overflow-hidden
                bg-white
                rounded-full
                shadow-md
              "
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div class="flex items-center justify-center w-12 bg-pink-500">
                <svg
                  class="w-6 h-6 text-white fill-current"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
                  ></path>
                </svg>
              </div>

              <div class="px-4 py-2 -mx-3">
                <div class="mx-3 text-left">
                  <span class="font-semibold text-pink-500">{{
                    notification.title
                  }}</span>
                  <p class="text-sm text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>
    <div class="flex flex-wrap w-full">
      <img
        style="height: 1100px"
        :src="require('@/assets/flirttoolbackground.jpg')"
        class="absolute inset-0 justify-center object-cover w-full h-full sm:"
        alt=""
      />
      <!-- Login Section -->
      <div
        class="
          px-4
          py-5
          mx-auto
          sm:max-w-xl
          md:max-w-full
          lg:max-w-screen-lg
          md:px-24
          lg:px-8 lg:py-1
        "
      >
        <div
          class="
            flex flex-col
            justify-center
            bg-white
            shadow-2xl
            rounded-3xl
            opacity-95
            px-8
            pt-8
            my-auto
            md:justify-start md:pt-0 md:px-24
            lg:px-56
            relative
            h-full
            lg:full
          "
        >
          <router-link to="/">
            <div class="flex flex-col flex-wrap content-center mt-3 mb-16">
              <img
                class="float-left h-20"
                :src="require('../assets/logo-light.png')"
                alt="Connect with flirttool & start your personal online dating adventure! "
              />
            </div>
          </router-link>
          <p
            class="
              mb-0
              text-lg
              font-bold
              text-center text-gray-800
              uppercase
              dark:text-gray-200
              md:text-sm
              text-light
              lg:text-2xl
              font-sans
            "
          >
            Welcome to <span class="text-pink-500">FlirtTool</span>
          </p>
          <div
            class="grid grid-cols-1 gap-2 mt-1 md:grid-cols-2 sm:grid-cols-2"
          >
            <div class="grid grid-cols-1 mt-5 mr-0 md:mr-0">
              <button
                type="button"
                @click="socialLoginGoogle"
                class="
                  mt-5
                  mb-3
                  bg-red-600
                  btn
                  hover:bg-red-700
                  rounded-full
                  shadow-2xl
                "
              >
                <img
                  :src="require('@/assets/gwhite.png')"
                  class="w-10 h-10"
                  alt="Connect with flirttool & start your personal online dating adventure!"
                />
                <span class="px-2 font-sans text-lg text-white dark:text-white">
                  Google
                </span>
              </button>
            </div>
            <div class="grid grid-cols-1 mr-0 md:mr-0">
              <button
                type="button"
                @click="socialLoginFb"
                class="
                  bg-blue-500
                  btn btn-outline-none
                  md:mt-10
                  xl:mt-10
                  lg:mt-10
                  hover:bg-blue-900
                  rounded-full
                  shadow-2xl
                "
              >
                <img
                  :src="require('@/assets/facebook.png')"
                  class="w-10 h-10"
                  alt="Connect with flirttool & start your personal online dating adventure!"
                />

                <span class="px-2 font-sans text-lg text-white dark:text-white">
                  Facebook
                </span>
              </button>
            </div>
          </div>

          <form class="flex flex-col pt-3 md:pt-8">
            <!-- first row start -->
            <div class="grid grid-cols-2 gap-5 mt-5 md:grid-cols-2 md:gap-8">
              <div class="grid grid-cols-1 mt-5 mr-0 md:mr-0">
                <label
                  class="
                    mb-5
                    text-xs
                    font-semibold
                    text-left text-gray-500
                    uppercase
                    dark:text-gray-200
                    md:text-sm
                    text-light
                  "
                  >First Name</label
                >
                <input
                  class="
                    px-3
                    py-2
                    mt-1
                    border-2 border-pink-300
                    rounded-full
                    dark:text-gray-200 dark:bg-gray-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-pink-400
                    focus:border-transparent
                  "
                  :class="{
                    'border-red-500 focus:ring-2 focus:ring-red-500':
                      !$v.firstName.required,
                  }"
                  v-model.trim="$v.firstName.$model"
                  @input="setFname($event.target.value)"
                  type="text"
                  placeholder="First Name"
                />
                <!-- validation area start -->
                <div
                  class="pt-2 text-left text-red-500 error text-xs"
                  v-if="!$v.firstName.required"
                >
                  First name is required
                </div>
                <!-- end of validation area -->
              </div>
              <div class="grid grid-cols-1 mt-5 mr-0 md:mr-0">
                <label
                  class="
                    mb-5
                    text-xs
                    font-semibold
                    text-left text-gray-500
                    uppercase
                    dark:text-gray-200
                    md:text-sm
                    text-light
                  "
                  >Last Name</label
                >
                <input
                  class="
                    px-3
                    py-2
                    mt-1
                    border-2 border-pink-300
                    rounded-full
                    dark:text-gray-200 dark:bg-gray-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-pink-500
                    focus:border-transparent
                  "
                  :class="{
                    'border-red-500 focus:ring-2 focus:ring-red-500':
                      !$v.lastName.required,
                  }"
                  v-model.trim="$v.lastName.$model"
                  @input="setLname($event.target.value)"
                  type="text"
                  placeholder="Last Name"
                />
                <div
                  class="pt-2 text-left text-red-500 error text-xs"
                  v-if="!$v.lastName.required"
                >
                  Last name is required
                </div>
              </div>
            </div>
            <!-- first row end -->

            <!-- first row start -->
            <div class="grid grid-cols-2 gap-5 mt-5 md:grid-cols-2 md:gap-8">
              <div class="grid grid-cols-1 mt-5 mr-0 md:mr-0">
                <label
                  class="
                    mb-5
                    text-xs
                    font-semibold
                    text-left text-gray-500
                    uppercase
                    md:text-sm
                    text-light
                    dark:text-gray-200
                  "
                  >Username</label
                >
                <input
                  class="
                    px-3
                    py-2
                    mt-1
                    border-2 border-pink-300
                    rounded-full
                    dark:bg-gray-700 dark:text-gray-200
                    focus:outline-none
                    focus:ring-2
                    focus:ring-pink-400
                    focus:border-transparent
                  "
                  :class="{
                    'border-red-500 focus:ring-2 focus:ring-red-500':
                      !$v.username.required,
                  }"
                  v-model.trim="$v.username.$model"
                  @input="setUname($event.target.value)"
                  type="text"
                  placeholder="Username"
                />
                <div
                  class="pt-2 text-left text-red-500 error text-xs"
                  v-if="!$v.username.required"
                >
                  Username is required
                </div>
                <div
                  class="pt-2 text-left text-red-500 error text-xs"
                  v-if="!$v.username.minLength"
                >
                  Username must have at least
                  {{ $v.username.$params.minLength.min }} letters.
                </div>
              </div>
              <div class="grid grid-cols-1 mt-5 mr-0 md:mr-0">
                <label
                  class="
                    mb-5
                    text-xs
                    font-semibold
                    text-left text-gray-500
                    uppercase
                    md:text-sm
                    text-light
                    dark:text-gray-200
                  "
                  >E-mail</label
                >
                <input
                  class="
                    px-3
                    py-2
                    mt-1
                    border-2 border-pink-300
                    rounded-full
                    dark:bg-gray-700 dark:text-gray-200
                    focus:outline-none
                    focus:ring-2
                    focus:ring-pink-500
                    focus:border-transparent
                  "
                  :class="{
                    'border-red-500 focus:ring-2 focus:ring-red-500':
                      !$v.email.required,
                  }"
                  v-model.trim="$v.email.$model"
                  @input="setEmail($event.target.value)"
                  type="text"
                  placeholder="E-mail"
                />
                <div
                  class="pt-2 text-left text-red-500 error text-xs"
                  v-if="!$v.email.required"
                >
                  E-mail is required
                </div>
                <div
                  class="pt-2 text-left text-red-500 error text-xs"
                  v-if="!$v.email.email"
                >
                  Email is not valid
                </div>
              </div>
            </div>
            <!-- first row end -->

            <!-- second row start -->
            <div class="grid grid-cols-2 gap-5 md:grid-cols-2 md:gap-8">
              <div class="grid grid-cols-1 mt-5 mr-0 md:mr-0">
                <label class="label dark:text-gray-200">
                  <span
                    class="
                      mb-5
                      text-xs
                      font-semibold
                      text-left text-gray-500
                      uppercase
                      md:text-sm
                      text-light
                      dark:text-gray-200
                    "
                    >Your password</span
                  >
                </label>

                <div class="relative">
                  <input
                    @keydown.enter="doSignUp"
                    :class="{
                      'border-red-500 focus:ring-2 focus:ring-red-500':
                        !$v.password.required,
                    }"
                    v-model.trim="$v.password.$model"
                    @input="setPass($event.target.value)"
                    class="
                      w-full
                      px-3
                      py-2
                      mt-1
                      border-2 border-pink-300
                      rounded-full
                      dark:bg-gray-700 dark:text-gray-200
                      focus:outline-none
                      focus:ring-2
                      focus:ring-pink-400
                      focus:border-transparent
                    "
                    id="my-textfield"
                    :type="showPass ? 'text' : 'password'"
                    value=""
                    placeholder="Password"
                  />
                  <div
                    class="pt-2 text-left text-red-500 error text-xs"
                    v-if="!$v.password.required"
                  >
                    Password is required
                  </div>
                  <div
                    class="pt-2 text-left text-red-500 error text-xs"
                    v-if="!$v.password.minLength"
                  >
                    Password is not valid
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 mt-5 mr-0 md:mr-0">
                <label class="label dark:text-gray-200">
                  <span
                    class="
                      mb-5
                      text-xs
                      font-semibold
                      text-left text-gray-500
                      uppercase
                      md:text-sm
                      text-light
                      dark:text-gray-200
                    "
                    >Confirm password</span
                  >
                </label>

                <div class="relative">
                  <input
                    @keydown.enter="doSignUp"
                    :class="{
                      'border-red-500 focus:ring-2 focus:ring-red-500':
                        !$v.c_password.required,
                    }"
                    v-model.trim="$v.c_password.$model"
                    @input="setCpass($event.target.value)"
                    class="
                      w-full
                      px-3
                      py-2
                      mt-1
                      border-2 border-pink-300
                      rounded-full
                      dark:bg-gray-700
                      focus:outline-none
                      dark:text-gray-200
                      focus:ring-2 focus:ring-pink-400 focus:border-transparent
                    "
                    id="my-textfield"
                    :type="showPass ? 'text' : 'password'"
                    value=""
                    placeholder="Password"
                  />
                  <!-- <div
										class="pt-2 text-left text-red-500 error text-xs"
										v-if="!$v.c_password.required"
									>
										Confirmation required
									</div> -->
                  <div
                    class="pt-2 text-left text-red-500 error text-xs"
                    v-if="!$v.c_password.sameAsPassword"
                  >
                    Password is not valid
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-2 text-left text-red-500 error text-xs">
              <label class="inline-flex items-center mt-3 text-left">
                <input
                  @change="termsChanged"
                  v-model="acceptedTerms"
                  type="checkbox"
                  class="w-5 h-5 text-pink-600 form-checkbox"
                  checked
                /><span class="ml-2 text-gray-700 dark:text-gray-200"
                  >I agree to the terms, conditions and privacy policy
                  <a class="text-blue-400" href="https://flirttool.com/terms"
                    >Click Here To View Term</a
                  >
                </span>
              </label>
            </div>
            <!-- second row end -->
            <button
              type="button"
              class="
                p-2
                mt-5
                text-sm text-white
                bg-pink-500
                rounded-full
                shadow-2xl
                font-semi-bold
                btn
                hover:bg-pink-600
              "
              :disabled="loadingRegister"
              @click="doSignUp"
              style="cursor: pointer"
            >
              <div v-if="loadingRegister" class="mt-3 lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p v-else>Register</p>
            </button>
          </form>
          <p
            class="
              mx-4
              mb-0
              text-xs
              font-semibold
              text-gray-500
              uppercase
              dark:text-gray-200
              md:text-sm
              text-light
              mt-4
            "
          >
            Already have an account?<br />
            <router-link
              to="/login"
              class="font-semibold text-pink-500 underline"
            >
              Login Here.
            </router-link>
          </p>
          <p
            class="
              mt-5
              mb-3
              font-semibold
              text-center text-gray-500
              uppercase
              text-2xs
              dark:text-gray-200
              md:text-sm
              text-light
            "
          >
            MAZU™
            <br />
            Powered by Dialogmakers International Ltd. 2021<br />
            <router-link to="/terms" class="text-pink-500"> Terms </router-link
            >|<router-link to="/privacy" class="text-pink-500">
              Privacy </router-link
            >|<router-link to="/imprint" class="text-pink-500">
              Imprint</router-link
            >
          </p>
          <div class="pt-12 pb-2 text-center dark:text-gray-200"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { register } from "../utils/basicAuth";
import { createProvider, onLogin } from "../v-apollo";
import { sameAs, required, minLength, email } from "vuelidate/lib/validators";
import { UPDATE_LOC } from "../queries/matches";
import { SOCIAL_AUTH_GOOGLE } from "../queries/auth";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
export default {
  data: () => ({
    email: "",
    username: "",
    password: "",
    c_password: "",
    firstName: "",
    lastName: "",
    showPass: false,
    afStr: null,
    loadingRegister: false,
    acceptedTerms: false,
  }),
  created() {
    this.setAddress();
    const afStr = this.$route.query.af;
    // check if the affilate link is valid
    if (afStr) {
      this.afStr = afStr;
    }
  },
  methods: {
    socialLoginFb() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
      this.loadingSocialAuth = true;
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          console.warn("*************************");
          console.warn(result);
          console.warn("*************************");
          await this.$apollo
            .mutate({
              mutation: SOCIAL_AUTH_GOOGLE,
              variables: {
                addr: this.$store.state.core.addr,
                input: result.user.accessToken,
              },
            })
            .then(
              ({
                data: {
                  socialMediaRegisterGoogle: { errors, user, credit, token },
                },
              }) => {
                // onLogin(createProvider().defaultClient)
                this.$store.commit("setUserData", user);
                credit
                  //? this.$store.commit("setBalance", credit.balance)
                  ? this.$store.commit("setBalance", credit.balance + credit.flirtons)
                  : null,
                  this.$store.commit("setRandState", Math.random(100));
                this.$store.commit("setFlirtTok", token);
                // reset apollo client
                // console.log(user, "user is logged in");
                // window.location.assign("/")
                if (user.profileComplete) {
                  // this.$router.push("/find-matches");
                  window.location.assign("/find-matches");
                } else {
                  this.$router.push("/profile-setup");
                }
                console.error(errors);
                console.warn(user);
              }
            );
        })
        .catch((err) => {
          const errCode = err.code;
          console.error(err, errCode);
        })
        .finally(() => {
          this.loadingSocialAuth = false;
        });
    },
    socialLoginGoogle() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      this.loadingSocialAuth = true;
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          await this.$apollo
            .mutate({
              mutation: SOCIAL_AUTH_GOOGLE,
              variables: {
                addr: this.$store.state.core.addr,
                input: result.user.accessToken,
              },
            })
            .then(
              ({
                data: {
                  socialMediaRegisterGoogle: { errors, user, credit, token },
                },
              }) => {
                onLogin(createProvider().defaultClient);
                this.$store.commit("setUserData", user);
                credit
                  ? this.$store.commit("setBalance", credit.balance + credit.flirtons)
                  : null,
                  this.$store.commit("setRandState", Math.random(100));
                this.$store.commit("setFlirtTok", token);
                // reset apollo client
                // console.log(user, "user is logged in");
                // window.location.assign("/")
                if (user.profileComplete) {
                  // this.$router.push("/find-matches");
                  window.location.assign("/find-matches");
                } else {
                  this.$router.push("/profile-setup");
                }
                console.error(errors);
                console.warn(user);
              }
            );
        })
        .catch((err) => {
          const errCode = err.code;
          console.error(err, errCode);
        })
        .finally(() => {
          this.loadingSocialAuth = false;
        });
    },
    setAddress() {
      axios
        .get(
          "https://ipapi.co/json/?key=VIqRa8XLW2h12erXOpubgCtj0xrCgkG6JHe8vNXuEfi7PMRo29"
        )
        .then(({ data }) => {
          this.$store.commit("setAddr", data.ip);
          this.$apollo.mutate({
            mutation: UPDATE_LOC,
            variables: {
              addr: data.ip,
            },
          });
        });
    },
    doSignUp() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      if (!this.$v.$invalid) {
        this.loadingRegister = true;
        register(
          this.username,
          this.email,
          this.firstName,
          this.lastName,
          this.password,
          this.afStr,
          this.$store.state.core.addr,
          ({ data, fail }) => {
            this.loadingRegister = false;
            if (!fail) {
              const { user, token, credit } = data.register;
              this.$store.commit("setUserData", user);
              //this.$store.commit("setBalance", credit.balance);
              this.$store.commit("setBalance", credit.balance + credit.flirtons);
              this.$store.commit("setRandState", Math.random(100));
              this.$store.commit("setFlirtTok", token);
              this.$router.push("/find-matches");
            } else {
              console.error("Error creating a new user", Object.keys(data));
            }
          }
        );
      }
    },
    setFname(value) {
      this.firstName = value;
      this.$v.firstName.$touch();
    },
    setLname(value) {
      this.lastName = value;
      this.$v.lastName.$touch();
    },
    setPass(value) {
      this.password = value;
      this.$v.password.$touch();
    },
    setCpass(value) {
      this.c_password = value;
      this.$v.c_password.$touch();
    },
    setUname(value) {
      this.username = value;
      this.$v.username.$touch();
    },
    setEmail(value) {
      this.email = value;
      this.$v.email.$touch();
    },
  },
  validations: {
    username: {
      required,
      minLength: minLength(4),
    },
    email: {
      required,
      email,
    },
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    c_password: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
};
</script>
<style scoped>
p {
  padding: 0px;
  display: block;
}
</style>