<template>
  <div>
    <notificationGroup group="top" position="top">
      <div
        style="transform: scale(80%)"
        class="
          fixed
          inset-x-0
          z-10
          flex
          items-start
          justify-end
          p-6
          px-4
          py-6
          mt-5
          pointer-events-no
        "
      >
        <div class="w-full max-w-sm">
          <notification v-slot="{ notifications }">
            <div
              class="
                flex
                w-full
                max-w-sm
                mx-auto
                mt-4
                overflow-hidden
                bg-white
                rounded-lg
                shadow-md
              "
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div class="flex items-center justify-center w-12 bg-pink-500">
                <svg
                  class="w-6 h-6 text-white fill-current"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
                  ></path>
                </svg>
              </div>

              <div class="px-4 py-2 -mx-3">
                <div class="mx-3 text-left">
                  <span class="font-semibold text-pink-500">{{
                    notification.title
                  }}</span>
                  <p class="text-sm text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>

    <div
      class="
        flex flex-wrap
        w-full
        h-full
        object-cover
        justify-left
        dark:text-gray-800
      "
      style="object-fit: cover; width: 100%; height: 100%"
    >
      <!-- Login Section -->
      <img
        style="height: 1100px"
        :src="require('@/assets/mazutheme/Background.png')"
        class="absolute inset-0 justify-center object-cover w-full h-full sm:"
        alt=""
      />
      <div
        class="
          px-2
            py-5
            sm:max-w-xla
            md:max-w-full
            lg:max-w-screen-xl
            md:px-24
            lg:px-36 lg:py-20
        "
      >
        <div
          class="
            flex flex-col
            justify-end
            bg-black bg-opacity-80
            shadow-2xl
            rounded-lg
            opacity-95
            md:justify-start md:pt-0
            px-6
            
            relative
            h-full
            lg:full
           
          "
        >
          <!-- <router-link to="/">
            <div
              class="
                flex flex-col flex-wrap
                content-center
                mb-5
                xl:mb-16
                lg:mb-16
                md:mb-16
                sm:mb-10
                mt-5
              "
            >
              <img
                class="float-left h-20"
                :src="require('../assets/logo-light.png')"
                alt="Connect with flirttool & start your personal online dating adventure! "
              />
            </div>
          </router-link> -->
          <p
            class="
              mb-0
              text-lg
              font-bold
              text-center text-gray-800
              uppercase
              dark:text-gray-800
              md:text-sm
              text-light
              lg:text-2xl
              font-sans
              mt-5
            "
          >
          <img src="@/assets/mazutheme/mazuyellowlogo.png" class="lg:pl-16 pl-10" alt="">
           <!-- <span class="text-gray-800 text-3xl lg:text-5xl xl:text-5xl">Quick Start
</span> -->
          </p><p
            class="
              mx-4
              mb-0
              mt-5
              text-xs
              font-semibold
              text-yellow-500
              uppercase
              dark:text-gray-800
              md:text-lg
              text-light
            "
          >
            Enter your login Credentials<br /> <span class="text-sm">or  <router-link to="/register"><span class="text-white">register here</span></router-link> </span>
            <!-- <router-link
              to="/register"
              class="font-semibold text-pink-500 underline"
            >
              Register here.
            </router-link> -->
          </p>
          <form class="flex flex-col pt-3 md:pt-8" v-if="!loadingSocialAuth">
            <div class="flex flex-col pt-4">
              <div class="bg-pink-100 alert" v-if="authErr">
                <div class="flex-1">
                  <label>
                    {{ authErr }}
                  </label>
                </div>
              </div>
              <div class="form-control">
                <label class="label">
                  <span
                    class="
                      mb-1
                      text-xs
                      md:text-2xs
                      sm:text-2xs
                      font-semibold
                      text-left text-yellow-500
                      uppercase
                      dark:text-gray-800
                      md:text-sm
                      text-light
                    "
                    >Username or Email</span
                  >
                </label>
                <input
                  @blur="usernameFocus = false"
                  @focus="usernameFocus = true"
                  v-model.trim="$v.emailOrUname.$model"
                  @input="setUname($event.target.value)"
                  class="
                    px-3
                   
                    mt-1
                    border-2 border-pink-300
                    rounded-full py-3
                    form__input
                    shadow-xl
                    dark:bg-gray-700
                    focus:outline-none
                    focus:ring-2 
                    focus:ring-yellow-400
                    focus:border-transparent
                    dark:text-gray-800
                  "
                  :class="{
                    'border-yellow-500 focus:ring-2 focus:ring-yellow-500':
                      !$v.emailOrUname.required,
                  }"
                  id="my-textfield"
                  type="text"
                  value=""
                  placeholder="Username"
                  @keydown.enter="doLogin"
                />
              </div>
              <div
                class="pt-2 text-left text-red-500 error"
                v-if="!$v.emailOrUname.required && usernameFocus"
              >
                Field is required
              </div>
              <div
                class="pt-2 text-left text-red-500 error"
                v-if="!$v.emailOrUname.minLength"
              >
                Name must have at least
                {{ $v.emailOrUname.$params.minLength.min }} letters.
              </div>
            </div>

            <div class="flex flex-col pt-4">
              <div class="form-control">
                <label class="label">
                  <span
                    class="
                      mb-1
                      font-semi-bold
                      text-left text-yellow-500
                      uppercase
                      dark:text-gray-800
                      text-xs
                    "
                    >Your password</span
                  >
                </label>

                <div class="relative">
                  <input
                    @blur="passFocus = false"
                    @focus="passFocus = true"
                    @keydown.enter="doLogin"
                    @input="setPass($event.target.value)"
                    v-model.trim="$v.password.$model"
                    class="
                      w-full
                      px-3
                      py-3
                      mt-1
                      border-2 border-yellow-300
                      rounded-full
                      shadow-xl
                      dark:bg-gray-700 dark:text-gray-800
                      focus:outline-none
                      focus:ring-2
                      focus:ring-yellow-400
                      focus:border-transparent
                    "
                    :class="{
                      'border-red-500 focus:ring-2 focus:ring-red-500':
                        !$v.password.required,
                    }"
                    id="my-textfield"
                    :type="showPass ? 'text' : 'password'"
                    value=""
                    placeholder="Password"
                  />
                  <!-- validation errors start -->
                  <div
                    class="pt-2 text-left text-red-500 error"
                    v-if="!$v.password.required && passFocus"
                  >
                    Password is required
                  </div>
                  <div class="pt-2 text-center font-sans font-semi-bold text-yellow-500 error">
                    <p class="mx-2 mt-5 text-sm font-sans">
                      Forgot password ?
                      <router-link to="/initial-reset" class="font-semi-bold italic font-sans text-yellow-50">Reset</router-link>
                    </p>
                    <label class="inline-flex items-center mt-3 text-left">
                      <input
                        @change="termsChanged"
                        v-model="acceptedTerms"
                        type="checkbox"
                        class="
                          w-5
                          h-5
                          border-2 border-pink-500
                          text-yellow-600
                          form-checkbox
                        "
                        checked
                      /><span
                        class="
                          mx-4
                          mb-0
                          text-2xs
                          mt-5
                          font-semibold
                          text-left text-yellow-500
                          uppercase
                          dark:text-gray-800
                          md:text-sm
                          text-light
                        "
                        >I AGREE TO THE TERMS, CONDITIONS AND PRIVACY
POLICY <br>
                        <a
                          class="text-yellow-50"
                          href="https://flirttool.com/terms"
                          >Click here to view our terms</a
                        >
                      </span>
                    </label>
                  </div>
                  
                  <!-- validation errors end -->
                  <button
                    type="button"
                    class="
                      absolute
                      top-0
                      right-0
                      mx-2
                      mt-5
                      btn-sm
                      bg-pink
                      btn-outline btn btn-primary
                      border-0
                    "
                    v-text="showPass ? 'Hide' : 'Show'"
                    @click="showPass = !showPass"
                  >
                    Show
                  </button>
                </div>
              </div>
            </div>
            <div><button
              type="button"
              class="
                p- 
                mt-5
                 w-72  justify-center
                 text-white
                bg-yellow-500
                rounded-full
                shadow-2xl
                font-semi-bold
                btn btn-lg 
                hover:bg-yellow-600
                font-sans
                text-lg
                
              "
              :disabled="loadingLogin"
              @click="doLogin"
              style="cursor: pointer"
            >
              <div v-if="loadingLogin" class="mt-3 lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p v-else>Log in</p>
            </button></div>
            
            <div
              class="grid grid-cols-2 gap-0 mt-1 md:grid-cols-1 sm:grid-cols-2"
            >
              <div class="grid lg:grid-cols-1 mt-5 mr-0 md:mr-0  hover:text-white">
                <button
                  type="button"
                  @click="socialLoginGoogle"
                  class="
                    mt-5
                    mb-3
                    bg-gray-50
                    btn
                    hover:bg-yellow-400
                    hover:text-white
                    rounded-lg
                    shadow-2xl
                    border-pink-500
                  "
                >
                  <img
                    :src="require('@/assets/google_logo_png_suite_everything_you_need_know_about_google_newest.png')"
                    class="w-10 h-10"
                    alt="Connect with flirttool & start your personal online dating adventure!"
                  />
                  <span
                    class="px-2 font-sans text-sm  hover:text-white text-gray-700 dark:text-white"
                  >
                   Log in with Google
                  </span>
                </button>
              </div>
              <div class="grid grid-cols-1 mr-0 md:mr-0">
                <button
                  type="button"
                  @click="socialLoginFb"
                  class="
                   mt-5
                    mb-3
                    bg-gray-50
                    btn
                    hover:bg-yellow-400
                    hover:text-white
                    rounded-lg
                    shadow-2xl
                    border-pink-500
                  "
                >
                  <img
                    :src="require('@/assets/Facebook_f_logo_(2019).svg.webp')"
                    class="w-10 h-10"
                    alt="Connect with flirttool & start your personal online dating adventure!"
                  />

                  <span
                    class="px-2 font-sans text-sm text-gray-700  hover:text-white  dark:text-white"
                  >
                    Continue with Facebook
                  </span>
                </button>
              </div>
            </div>
            <!-- <button
              type="button"
              class="
                p-2
                mt-5
                text-sm text-white
                bg-pink-500
                rounded-full
                shadow-2xl
                font-semi-bold
                btn
                hover:bg-pink-600
              "
              :disabled="loadingLogin"
              @click="doLogin"
              style="cursor: pointer"
            >
              <div v-if="loadingLogin" class="mt-3 lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <p v-else>Log in</p>
            </button> -->
          </form>
          <center v-else class="mt-10">
            <div class="mt-3 lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </center>
          <!-- <p
            class="
              mx-4
              mb-0
              mt-5
              text-xs
              font-semibold
              text-gray-500
              uppercase
              dark:text-gray-800
              md:text-sm
              text-light
            "
          >
            Don't have an account?<br />
            <router-link
              to="/register"
              class="font-semibold text-pink-500 underline"
            >
              Register here.
            </router-link>
          </p> -->
          <p
            class="
              mt-16
              mb-5
              font-semibold
              text-center text-yellow-500
              uppercase
              text-2xs
              dark:text-gray-800
              md:text-2xs
              text-light
            "
          >
            MAZU™
           
            Powered by Dialogmakers International Ltd. 2021<br />
            <router-link to="/terms" class="text-yellow-50"> Terms </router-link
            >|<router-link to="/privacy" class="text-yellow-50">
              Privacy </router-link
            >|<router-link to="/imprint" class="text-yellow-50">
              Imprint</router-link
            >
          </p>
          <!--<div
            class="pt-3 mt-8 mb-16 text-center lg:pt-12 xl:pt-12 md:pt-10 sm:pt-5 lg:pb-12 lg:mb-0 md:mb-0 xl:mb-0"
          >
            <p
              class="mx-4 mb-0 text-xs font-semibold text-gray-500 uppercase dark:text-gray-800 md:text-sm text-light"
            >
              Don't have an account?<br />
              <router-link to="/register" class="font-semibold text-pink-500 underline">
                Register here.
              </router-link>
            </p>
            <p
              class="mt-16 mb-5 font-semibold text-center text-gray-500 uppercase text-2xs dark:text-gray-800 md:text-sm text-light"
            >
              MAZU™
              <br />
              Powered by Dialogmakers International Ltd. 2021<br />
              <router-link to="/terms" class="text-pink-500"> Terms </router-link
              >|<router-link to="/privacy" class="text-pink-500"> Privacy </router-link
              >|<router-link to="/imprint" class="text-pink-500"> Imprint</router-link>
            </p>
          </div>-->
        </div>
      </div>

      <!--<div class="w-1/2 shadow-2xl">
        <img
					class="hidden object-cover w-full h-screen md:block"
					:src="require('../assets/loginSide.jpeg')"
					alt="Connect with flirttool & start your personal online dating adventure!"
					style="filter: grayscale(0.3)"
				/>
      </div>-->
    </div>
    <!--new section-->

    <!--new section-->
  </div>
</template>
<script>
import axios from "axios";
import { UPDATE_LOC } from "@/queries/matches";
import { createProvider, onLogin } from "@/v-apollo";
import { required, minLength } from "vuelidate/lib/validators";
import { SOCIAL_AUTH_GOOGLE, USER_LOGIN } from "@/queries/auth";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
export default {
  data: () => ({
    showPass: false,
    emailOrUname: "",
    password: "",
    passFocus: false,
    loadingLogin: false,
    usernameFocus: false,
    authErr: "",
    acceptedTerms: false,
    loadingSocialAuth: false,
  }),
  created() {
    console.clear();
    console.warn(this.$route.query, "dog");
    this.setAddress();
    if (this.$store.state.core.flirtok) {
      this.$router.push("/find-matches");
    }
  },
  methods: {
    setAddress() {
      axios
        .get(
          "https://ipapi.co/json/?key=VIqRa8XLW2h12erXOpubgCtj0xrCgkG6JHe8vNXuEfi7PMRo29"
        )
        .then(({ data }) => {
          this.$store.commit("setAddr", data.ip);
          this.$apollo.mutate({
            mutation: UPDATE_LOC,
            variables: {
              addr: data.ip,
            },
          });
        });
    },
    socialLoginFb() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      const auth = getAuth();
      const provider = new FacebookAuthProvider();
      this.loadingSocialAuth = true;
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          console.warn("*************************");
          console.warn(result);
          console.warn("*************************");
          await this.$apollo
            .mutate({
              mutation: SOCIAL_AUTH_GOOGLE,
              variables: {
                addr: this.$store.state.core.addr,
                input: result.user.accessToken,
              },
            })
            .then(
              ({
                data: {
                  socialMediaRegisterGoogle: { errors, user, credit, token },
                },
              }) => {
                // onLogin(createProvider().defaultClient)
                this.$store.commit("setUserData", user);
                credit.flirtonsAvailable === true 
                ? this.$store.commit("setFlirtonsAvailable",true) :
                this.$store.commit("setFlirtonsAvailable",false)
                credit
                  //? this.$store.commit("setBalance", credit.balance)
                  ?  this.$store.commit("setBalance", credit.balance + credit.flirtons)
                  : null,
                  this.$store.commit("setRandState", Math.random(100));
                this.$store.commit("setFlirtTok", token);
                // reset apollo client
                // console.log(user, "user is logged in");
                // window.location.assign("/")
                if (user.profileComplete) {
                  // this.$router.push("/find-matches");
                  if (this.$route.query.to) {
                    window.location.assign("/" + this.$route.query.to);
                  } else {
                    window.location.assign("/find-matches");
                  }
                } else {
                  this.$router.push("/profile-setup");
                }
                console.error(errors);
                console.warn(user);
              }
            );
        })
        .catch((err) => {
          const errCode = err.code;
          console.error(err, errCode);
        })
        .finally(() => {
          this.loadingSocialAuth = false;
        });
    },
    socialLoginGoogle() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you continue",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      this.loadingSocialAuth = true;
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result)
          await this.$apollo
            .mutate({
              mutation: SOCIAL_AUTH_GOOGLE,
              variables: {
                addr: this.$store.state.core.addr,
                input: result.user.accessToken,
                 userType:'',
                preference:""
              },
            })
            .then(
              ({
                data: {
                  socialMediaRegisterGoogle: { errors, user, credit, token },
                },
              }) => {
                onLogin(createProvider().defaultClient);
                this.$store.commit("setUserData", user);
                credit.flirtonsAvailable === true 
                ? this.$store.commit("setFlirtonsAvailable",true) :
                this.$store.commit("setFlirtonsAvailable",false)
                credit
                  //? this.$store.commit("setBalance", credit.balance)
                  ? this.$store.commit("setBalance", credit.balance + credit.flirtons)
                  : null,
                  this.$store.commit("setRandState", Math.random(100));
                this.$store.commit("setFlirtTok", token);
                // reset apollo client
                // console.log(user, "user is logged in");
                // window.location.assign("/")
                if (user.profileComplete) {
                  if (this.$route.query.to) {
                    window.location.assign("/" + this.$route.query.to);
                  } else {
                    window.location.assign("/find-matches");
                  }
                } else {
                  this.$router.push("/profile-setup");
                }
                console.error(errors);
                console.warn(user);
              }
            );
        })
        .catch((err) => {
          const errCode = err.code;
          console.error(err, errCode);
        })
        .finally(() => {
          this.loadingSocialAuth = false;
        });
    },
    doLogin() {
      if (!this.acceptedTerms) {
        this.$notify(
          {
            group: "top",
            title: "you need to agree with the terms before you contine",
            text: "Auth Error",
            type: "info", // info, warning
          },
          5000
        );
        return;
      }
      if (!this.$v.$invalid) {
        this.loadingLogin = true;
        this.$apollo
          .getClient()
          .mutate({
            mutation: USER_LOGIN,
            // authErrs: [],
            variables: {
              addr: this.$store.state.core.addr,
              usernameOrEmail: this.emailOrUname,
              password: this.password,
            },
          })
          .then(({ data: { login } }) => {
            console.log(login, "What the heck");
            const { user, credit, token } = login;
            credit.flirtonsAvailable === true 
            ? this.$store.commit("setFlirtonsAvailable",true) :
             this.$store.commit("setFlirtonsAvailable",false)
            onLogin(createProvider().defaultClient);
            this.$store.commit("setUserData", user);
            //this.$store.commit("setBalance", credit.balance);
             this.$store.commit("setBalance", credit.balance + credit.flirtons);
            this.$store.commit("setRandState", Math.random(100));
            this.$store.commit("setFlirtTok", token);
            // reset apollo client
            // console.log(user, "user is logged in");
            // window.location.assign("/")
            if (user.profileComplete) {
              // this.$router.push("/find-matches");
              if (this.$route.query.to) {
                window.location.assign("/" + this.$route.query.to);
              } else {
                window.location.assign("/find-matches");
              }
            } else {
              this.$router.push("/profile-setup");
            }
          })
          .catch((e) => {
            console.warn(Object.keys(e));
            Object.keys(e).forEach((x) => {
              console.warn(e[x]);
            });
            console.error(e);
            this.authErr = "You have entered incorrect credentials";
          })
          .finally(() => {
            this.loadingLogin = false;
          });
      }
    },
    setUname(value) {
      this.emailOrUname = value;
      this.$v.emailOrUname.$touch();
    },
    setPass(value) {
      this.password = value;
      this.$v.password.$touch();
    },
  },
  validations: {
    emailOrUname: {
      required,
      minLength: minLength(4),
    },
    password: {
      required,
    },
  },
  metaInfo() {
    return {
      title: "Login | FlirtTool | The finest global  dating website around. ",
      meta: [
        {
          name: "description",
          content:
            " FlirtTool is the first dating community based on a special artificial intelligence (AI) system. Connect with local singles & start your personal online dating adventure!",
        },
        {
          property: "og:title",
          content:
            "Login | FlirtTool | The finest global  dating website around.",
        },
        { property: "og:site_name", content: "FlirtTool Online Dating" },
        { property: "og:type", content: "FlirtTool Online Dating" },
        { name: "googlebot", content: "index,follow" },
      ],
    };
  },
};
</script>
<style scoped>
p {
  padding: 0px;
  display: block;
}
</style>