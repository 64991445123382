<template>
  <div>
    <div class="relative" style="object-fit: ; width: 100%; height: 300px">
      <img
        src="@/assets/valentine/2.jpg"
        class="absolute inset-0 justify-center object-cover w-full h-full"
        alt=""
      />
      <div class="relative">
        <!--Navigation -->
        <!-- nav starts here -->
        <!-- nav ends here -->
        <div
          class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
        >
          <div class="flex flex-col items-center justify-between xl:flex-row">
            <div class="w-full max-w-xl xl:px-8 xl:w-5/12"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="relative">
  <img src="@/assets/valentine/6.jpg" class="absolute inset-0 object-cover w-full h-full" alt="" />
  <div class=" bg-gray-900 bg-opacity-0">
    <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
         
        </div>
    
        <img src="@/assets/valentine/5.png" alt="">
      </div>
      
    </div>
  </div>
 
</div> -->
    <div class="relative" style="object-fit: ; width: 100%">
      <img
        src="@/assets/valentine/6.jpg"
        class="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div class="relative">
        <div
          class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-5"
        >
          <div
            class="max-w-xl mx-auto mb-10 object-center mt-5 sm:text-center lg:max-w-2xl md:mb-12"
          >
            <div></div>

            <img src="@/assets/valentine/5.png" alt="" />
            <!-- <p class="text-white uppercase font-sans text-3xl">unlock the valentine gift</p> -->
          </div>
        </div>
      </div>
    </div>
    <!-- secttion 3 -->
    <div class="relative" style="object-fit: ; width: 100%">
      <img
        src="@/assets/valentine/1.jpg"
        class="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div class="relative">
        <div
          class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
        >
          <div
            class="max-w-xl mx-auto mb-10 object-center mt-5 sm:text-center lg:max-w-2xl md:mb-12"
          >
            <div></div>

            <img
              src="@/assets/valentine/lock.png"
              class="h-36 object-center object-contain mx-auto"
              alt=""
            />
            <p class="text-white uppercase font-sans text-3xl">
              unlock the valentine gift
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- section3 ends here -->

    <div class="relative">
      <img
        src="@/assets/valentine/7.jpg"
        class="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div class="relative bg-opacity-0">
        <div
          class="px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-5 lg:px-8 lg:py-5"
        >
          <div class="flex flex-col items-center justify-between xl:flex-row">
            <div
              class="px-4 bg-transparent py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
            >
              <p
                class="text-xl uppercase font-bold text-white text-left md:text-lg"
              >
                Photos
              </p>
              <div
                class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12"
              ></div>
              
                <Gallery />
               

               
               
             
              <!-- <p
                class="text-xl uppercase font-bold text-white text-left mb-9 md:text-lg"
              >
                Video
              </p>
              <div
                class="grid gap-10 sm:grid-cols-2 grid-cols-2 lg:grid-cols-3"
              >
                <div>
                  <div
                    class="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl"
                  >
                    <img
                      class="object-cover w-full h-56 md:h-64 xl:h-80"
                      src="https://cdn.pixabay.com/photo/2017/01/04/20/32/girl-1953201_960_720.jpg"
                      alt="Person"
                    />
                    <div
                      class="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100"
                    >
                      <p class="mb-1 text-lg font-bold text-gray-100">Unlock</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    class="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl"
                  >
                    <img
                      class="object-cover w-full h-56 md:h-64 xl:h-80"
                      src="https://cdn.pixabay.com/photo/2017/01/04/20/32/girl-1953201_960_720.jpg"
                      alt="Person"
                    />
                    <div
                      class="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100"
                    >
                      <p class="mb-1 text-lg font-bold text-gray-100">Unlock</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    class="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl"
                  >
                    <img
                      class="object-cover w-full h-56 md:h-64 xl:h-80"
                      src="https://cdn.pixabay.com/photo/2017/01/04/20/32/girl-1953201_960_720.jpg"
                      alt="Person"
                    />
                    <div
                      class="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100"
                    >
                      <p class="mb-1 text-lg font-bold text-gray-100">Unlock</p>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <button
                class="btn btn-wide mx-auto mt-16 border-0 bg-green-500 hover:bg-green-300 text-white"
              >
                Unlock All For $100
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- photosection starts here -->
    <div class="bg-red-900"></div>

    <!-- photosection ends her -->

    <!-- footer -->

    <div class="bg-black">
      <div
        class="px-4 pt-16 mx-auto text-left sm:max-w-xl md:max-w-full lg:max-w-screen-xl bg-black md:px-24 lg:px-8"
      >
        <div class="grid gap-10 row-gap-6 mb-8 sm:grid-cols-3 lg:grid-cols-4">
          <div class="sm:col-span-2">
            <div class="text-sm">
              <p
                class="font-bold font-sans text-xl tracking-wide uppercase text-gray-50"
              >
                About
              </p>
              <div class="flex">
                <p class="mr-1 text-red-500 font-sans text-sm">Flirttool</p>
              </div>
              <div class="flex">
                <p class="mr-1 text-red-500 text-sm font-sans">MAZU</p>
              </div>
              <div class="flex">
                <div class="flex">
                  <p class="mr-1 text-red-500 text-sm font-sans">
                    Press And Media
                  </p>
                </div>
              </div>
              <div class="flex">
                <div class="flex">
                  <p class="mr-1 text-red-500 text-sm font-sans">Blogs</p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-sm">
            <p class="font-bold tracking-wide text-xl uppercase text-gray-50">
              Contact Us
            </p>
            <div class="flex">
              <p class="mr-1 text-red-500 text-sm font-sans">
                Live Chat :<a
                  class="px-2 text-white hover:text-pink-300"
                  href="https://tawk.to/chat/60d8ca3565b7290ac6382e01/1f97dbp6v"
                  >Chat Now</a
                >
              </p>
            </div>
            <div class="flex">
              <p class="mr-1 text-red-500 text-sm font-sans">
                Email<a
                  href="mailto:support@flirttool.com
"
                  aria-label="Our email"
                  title="Our email"
                  class="transition-colors px-7 duration-300 text-pink-50 hover:text-pink-300"
                  >:support@flirttool.com
                </a>
              </p>
            </div>
            <div class="flex">
              <p class="mr-1 text-red-500 text-sm font-sans">
                Address<a
                  href="https://www.google.com/maps"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Our address"
                  title="Our address"
                  class="transition-colors duration-300 px-2 text-pink-50 hover:text-pink-300"
                >
                  :2F, No. 2-1, Lane 23, Wenhua Street Pingzhen District,
                  Taoyuan City, 324 Taiwan
                </a>
              </p>
            </div>
          </div>
          <div>
            <span
              class="text-xl font-sans font-bold mt-3 tracking-wide uppercase text-gray-50"
              >Social Media</span
            >
            <div class="flex items-center mt-1 space-x-3">
              <a
                traget="_blank"
                href="https://twitter.com/flirttool/"
                class="text-gray-50 transition-colors duration-300 hover:text-pink-300"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="h-5 hover:text-pink-300"
                >
                  <path
                    d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z"
                  ></path>
                </svg>
              </a>
              <a
                traget="_blank"
                href="https://www.instagram.com/flirttool/"
                class="text-gray-50 transition-colors duration-300 hover:text-pink-400"
              >
                <svg
                  viewBox="0 0 30 30"
                  fill="currentColor"
                  class="h-6 hover:text-pink-300"
                >
                  <circle cx="15" cy="15" r="4" class=""></circle>
                  <path
                    d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"
                  ></path>
                </svg>
              </a>
              <a
                traget="_blank"
                href="https://www.facebook.com/flirttool/"
                class="text-gray-50 transition-colors duration-300 hover:text-pink-300"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="h-5 hover:text-pink-300"
                >
                  <path
                    d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"
                  ></path>
                </svg>
              </a>
              <a
                traget="_blank"
                href="https://www.youtube.com/channel/UC9xQrnNCDf75e2yaXsbVSWA"
                class="text-gray-50 transition-colors duration-300 hover:text-pink-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="bi bi-youtube h-6 hover:text-pink-300"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row"
        >
          <p class="text-sm text-gray-50">
            © Copyright 2022 FlirtTool. All rights reserved. Powered by
            <a
              target="_blank"
              class="text-white hover:text-pink-300"
              href="https://dialogmakers-international.com/"
              >Dialogmakers International Ltd.</a
            >
          </p>
          <ul
            class="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row"
          >
            <!-- <li>
                        <a
                          href="/"
                          class="text-sm text-gray-50"
                          >F.A.Q</a
                        >
                      </li> -->
            <li>
              <a
                target="_blank"
                href="/imprint"
                class="text-sm hover:text-pink-300 text-gray-50"
                >Imprint</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="/privacy"
                class="text-sm hover:text-pink-300 text-gray-50"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="/terms"
                class="text-sm hover:text-pink-300 text-gray-50"
                >Terms &amp; Conditions</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- fo -->
  </div>
</template>
<script>
import Gallery from "../components/valentinegallery/Gallery.vue";
export default {
  components: {
    Gallery,
  },
};
</script>
