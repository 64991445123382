import gql from "graphql-tag";

export const FIND_MATCHES_LIST = gql`
  query find_matches($input: PaginationInput!, $addr: String!) {
    find_matches(input: $input, addr: $addr) {
      data {
        id
        photos {
          id
          image
        }
        username
        email
        createdAt
        profilePic
        tempLocationDetail
        userLocation
        usrType
        base_profile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const FIND_VIP_MATCHES_LIST = gql`
  query find_matches_vip($input: PaginationInput!) {
    find_matches_vip(input: $input) {
      data {
        id
        photos {
          id
          image
        }
        username
        email
        createdAt
        profilePic
        usrType
        base_profile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const MODEL_PICTURES = gql`
  query getModelPictures($username: String!) {
    getModelPictures(username: $username) {
      user {
        id
      }
      userSameAsContext
      modelMedia {
        id
        url
      }
      userPurchasedMedia {
        id
        is_purchased
        cost
        format
        is_paid
      }
    }
  }
`;

export const MATCHES = gql`
  query {
    matches {
      id
      matched_with {
        id
        username
        username
        email
        profilePic
        profileSetupStep
        profileComplete
        base_profile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const LIKE_PROFILE = gql`
  mutation like_profile($user: String!) {
    like_profile(user: $user)
  }
`;
export const DISLIKE_PROFILE = gql`
  mutation dislike_profile($user: String!) {
    dislike_profile(user: $user)
  }
`;

export const HAS_FLIRTONS = gql`
  query hasFlirtOns {
    hasFlirtOns
  }
`;

export const VISITS = gql`
  query {
    visits {
      id
      visitor {
        id
        username
        username
        email
        profilePic
        profileSetupStep
        profileComplete
        base_profile {
          id
          firstName
          lastName
        }
      }
      #   visitee{
      #     id
      #     username
      #   }
    }
  }
`;

export const LIKES = gql`
  query {
    likes {
      id
      liked_by {
        id
        username
        username
        email
        profilePic
        profileSetupStep
        profileComplete
        base_profile {
          id
          firstName
          lastName
        }
      }
      liked_status
      createdAt
    }
  }
`;
export const LIKED = gql`
  query {
    liked {
      id
      user {
        id
        username
        username
        email
        profilePic
        profileSetupStep
        profileComplete
        base_profile {
          id
          firstName
          lastName
        }
      }
      liked_status
      createdAt
    }
  }
`;
export const DISLIKES = gql`
  query {
    dislikes {
      id
      user {
        id
        username
        username
        email
        profilePic
        profileSetupStep
        profileComplete
        base_profile {
          id
          firstName
          lastName
        }
      }
      createdAt
    }
  }
`;

export const NOTIFICATIONS = gql`
  query {
    notifications {
      id
      read
      message
      sparoute
      link
      ref_user {
        id
        username
        email
        profilePic
        base_profile {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_PACKAGES = gql`
  query {
    all_packages {
      packages {
        id
        packageName
        packageDesc
        cover
        packageId
        creditValue
        packageType
        price
        euroPrice
        poundPrice
        tryPrice
        createdAt
        is_available
      }
      active_packages {
        id
        is_available
      }
    }
  }
`;

export const GET_FLIRTONS = gql`
  query {
    all_flirtons {
      id
      packageName
      packageDesc
      cover
      packageId
      creditValue
      packageType
      price
      createdAt
    }
  }
`;

export const PAYPAL_EVENT_CHECKOUT = gql`
  mutation createPaypalEventCheckout($input: EventCheckoutInput!) {
    createPaypalEventCheckout(input: $input) {
      href
      rel
      method
    }
  }
`;

export const STRIPE_EVENT_CHECKOUT = gql`
  mutation createEventsStripeCheckout($input: EventCheckoutInput!) {
    createEventsStripeCheckout(input: $input)
  }
`;

export const CHECKOUT_PAYPAL = gql`
  mutation createPaypalCheckout($input: CheckoutInput!) {
    createPaypalCheckout(input: $input) {
      href
      rel
      method
    }
  }
`;

export const UPDATE_LOC = gql`
  mutation updateUserAddr($addr: String!) {
    updateUserAddr(addr: $addr)
  }
`;

export const CHECKOUT_STRIPE = gql`
  mutation createStripeCheckout($input: CheckoutInput!) {
    createStripeCheckout(input: $input)
  }
`;

export const HOT_ORNOT_SET = gql`
  query hotOrNotUsers($input: PaginatedUsersInput!) {
    hotOrNotUsers(input: $input) {
      id
      username
      usrType
      base_profile {
        id
        firstName
        lastName
      }
      profilePic
    }
  }
`;

export const TRANSACTIONS = gql`
  query {
    transactions {
      id
      amount
      createdAt
      paymentSource
      transactionId
      transactionName
      transactionStatus
      package {
        id
        packageName
        packageId
        packageDesc
        creditValue
      }
    }
  }
`;

export const VERIFY_CPN = gql`
  mutation verifyCoupon($input: CouponVerificationInput!) {
    verifyCoupon(input: $input) {
      id
      stripePrice
      stripeProduct
      discount
    }
  }
`;
export const VERIFY_CPN_AC = gql`
  mutation initFreePackage($input: String!, $cpn: String!) {
    initFreePackage(input: $input, cpn: $cpn)
  }
`;

export const AFF_ACT = gql`
  query {
    affilateActivity {
      acts {
        id
        createdAt
        user {
          base_profile {
            id
            firstName
            lastName
          }
        }
        affilate {
          affilate {
            id
            username
            email
          }
        }
        creditAmount
        activityType
      }
      affilate {
        id
        affilateId
      }
      actToday
      actMonth
      actTotal
    }
  }
`;

export const REQUEST_POUT = gql`
  mutation requestPayout($amount: Float!, $afId: String!) {
    requestPayout(amount: $amount, afId: $afId)
  }
`;

export const GET_COMMUNITY_GALLERIES = gql`
  query getCommunityGalleries {
    getCommunityGalleries {
      id
      name
      poster
    }
  }
`;

export const GET_COMMUNITY_GALLERY_MEDIA = gql`
  query getCommunityGalleryMedia($gallery: Float!) {
    getCommunityGalleryMedia(gallery: $gallery) {
      id
      is_purchased
      cost
      format
      is_paid
    }
  }
`;

export const PURCHASE_MEDIA = gql`
  mutation purchaseMedia($media: Float!) {
    purchaseMedia(media: $media) {
      balance
      flirtons
    }
  }
`;


export const MODEL_MEDIA_COUNT = gql`
  query getModelMediaCount($username: String!) {
    getModelMediaCount(username: $username) {
      pictures
      videos
    }
  }
`;