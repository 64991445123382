<style>
.eeeb33c8-89bb-4b0a-b2c5-c86c57d0e4eb {
  fill: #a7afe9;
}
</style>

<template>
  <div class="grid grid-cols-1 gap-4">
    <!-- <div class="col-span-1 bordered">
      <div class="box-border w-full border-0 md:mx-20">
       
        <div class="hidden lg:block">
          <user-avatar />
          <side-selector />
        </div>
       
      </div>
    </div> -->

    <div>
    <ul class="w-full menu gap-2.5 bg-gray-100 dark:bg-gray-500 pl-1.5 ">
<router-link to="/">
      <li class="">
        <a> <img class="w-8 h-7 " src="@/assets/icons/HomeIcon.png" /></a>
      </li></router-link>
      <li><router-link to="/chat">
        <a> <img class="w-8 h-7" src="@/assets/Untitledchat.png" /></a></router-link>
      </li>
      <li class="">
        <a> <img class="w-8 h-7 cursor-not-allowed" src="@/assets/shopp.png" /></a>
      </li>
      <li class="">
        <a> <img class="w-8 h-7 cursor-not-allowed" src="@/assets/icons/webcam.png" /></a>
      </li>
      <li class="">
        <a> <img class="w-8 h-7 cursor-not-allowed" src="@/assets/icons/bf.png" /></a>
      </li>
      <li>
        
          <a target="_blank" href="https://www.youtube.com/channel/UC9xQrnNCDf75e2yaXsbVSWA"> <img class="w-8 h-5" src="@/assets/LateNightshows.png" /></a
        >
      </li>
      <li>
        <a target="_blank" href="https://flirttool.com/Ftinfinity"> <img class="w-8 h-5" src="@/assets/Infinity12344.png" /></a>
      </li>
     
      
    </ul> 
  </div>
    <div> <ul class="w-full menu gap-2.5 bg-gray-100 dark:bg-gray-500 pl-1.5 ">
      <!-- <li>
        <a> <img class="w-8 h-7" src="@/assets/Hearts.png" /></a>
      </li> --><li>
        <router-link to="/liked">
          <a> <img class="w-8 h-7" src="@/assets/ftfire.png" /></a
        ></router-link>
      </li> <li><router-link to="/matches">
        <a> <img class="w-8 h-7" src="@/assets/Matches.png" /></a></router-link>
      </li><li>
        
          <a> <img class="w-8 h-5 cursor-not-allowed" src="@/assets/icons/MAZU.png" /></a
        >
      </li>
      <li> <router-link to="/visitors">
        <a> <img class="w-8 h-5" src="@/assets/ViewsVisitors.png" /></a></router-link>
      </li>
      <li>
        <router-link to="/likes">
          <a> <img class="w-8 h-7" src="@/assets/Likes.png" /></a
        ></router-link>
      </li>
      
      <li>
        <a target="_blank" href="https://www.flirttool-lovestories.com/">
          <img class="w-8 h-7" src="@/assets/UserLoggingPage.png"
        /></a>
      </li>
      <li>
        <a target="_blank" href="https://www.flirttool-news.com/">
          <img class="w-8 h-5" src="@/assets/news123.png"
        /></a>
      </li>
      <!-- <li>
        <a target="_blank" href="https://www.flirttool-news.com/">
          <img class="w-8 h-5" src="@/assets/icons/Support.png"
        /></a>
      </li> -->
    </ul>
    </div>
  </div>
</template>
<style scoped></style>
<script>
// import UserAvatar from "../../components/userAvatar.vue";
// import SideSelector from "../../components/sideSelector.vue";
//import { VISITS } from "../queries/matches";
//import UserCards from "../components/userCards.vue";
const features = [
  {
    name: "Competitive exchange rates",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "GlobeAltIcon",
  },
  {
    name: "No hidden fees",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "ScaleIcon",
  },
  {
    name: "Transfers are instant",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "LightningBoltIcon",
  },
  {
    name: "Mobile notifications",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: "AnnotationIcon",
  },
];

export default {
  components: {
    //     UserAvatar,
    //   SideSelector,
    //UserCards,
  },
  apollo: {
    visits: {
      //query: VISITS,
    },
  },
  data: () => ({
    features,
    visits: null,
    loadingContent: true,
    features2: [
      {
        name: "No hidden fees",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "ScaleIcon",
      },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    features1: [
      // {
      //   name: "No hidden fees",
      //   description:
      //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      //   icon: "ScaleIcon",
      // },
      {
        name: "Transfers are instant",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: "LightningBoltIcon",
      },
    ],
    mobile: false,
  }),
  methods: {
    clicked() {
      alert("clicked");
    },
    detectOrientationChange() {
      switch (window.orientation) {
        case -90 || 90:
          // landscape
          this.mobile = false;
          break;
        default:
          // portrait
          this.mobile = true;
          break;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("onorientationchange", this.detectOrientationChange);
    });
  },
  created() {
    this.$apollo.queries.visits.refetch().finally(() => {
      this.loadingContent = false;
    });
    this.detectOrientationChange(); // when instance is created
  },
};
</script>
<style>
@import "../assets/css/style.css";
</style>
