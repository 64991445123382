<template>
	<div>
		<!-- nav starts here -->
        <div class="px-4 py-3 mx-auto bg-white sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div class="relative flex items-center justify-between">
          <a href="/" aria-label="Company" title="Company" class="inline-flex items-center">

            <img :src="getCommunityMeta.logo" class="h-16" alt="">
          </a>
          <ul class="flex items-center hidden space-x-8 lg:flex">
            <!-- <li><a href="/" aria-label="Our product" title="Our product"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Product</a>
            </li>
            <li><a href="/" aria-label="Our product" title="Our product"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Features</a>
            </li>
            <li><a href="/" aria-label="Product pricing" title="Product pricing"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">Pricing</a>
            </li>
            <li><a href="/" aria-label="About us" title="About us"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400">About
                us</a></li>
             --><li>
              <router-link to="/login">
              <a 
                class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide bg-purple-900 text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                aria-label="Sign up" title="Sign up">
                {{ $t('SignIn')}}
              </a></router-link>
            </li>
          </ul>
          <!-- Mobile menu -->
          <div class="lg:hidden">
            <button aria-label="Open Menu" title="Open Menu"
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50">
              <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                <path fill="currentColor" d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z">
                </path>
                <path fill="currentColor" d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"></path>
                <path fill="currentColor" d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z">
                </path>
              </svg>
            </button>
           
  
          </div>
        </div>
      </div>
      <!-- nav ends here -->
		<!-- Hero -->
		<section class="relative bg-white dark:bg-gray-50 ">
			<div class="mb-16">
				<div class="bg-white">
					<div
						class="
							px-4
							py-5
							mx-auto
							sm:max-w-xl
							md:max-w-full
							lg:max-w-screen-xl
							md:px-24
							lg:px-8
						"
					>
						<!-- <div class="relative flex items-center justify-between">
							<a
								href="/"
								aria-label="FunnyFlirts"
								title="FunnyFlirts"
								class="inline-flex items-center"
							>
								<img
									class="float-left h-16"
									:src="require('@/assets/logo-light.png')"
								/>
							</a>
							<ul class="flex items-center invisible hidden space-x-8 lg:flex">
								<li>
									<a
										href="/"
										aria-label="Our product"
										title="Our product"
										class="
											tracking-wide
											text-gray-800
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>Product</a
									>
								</li>
								<li>
									<a
										href="/"
										aria-label="Our product"
										title="Our product"
										class="
											tracking-wide
											text-gray-800
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>Features</a
									>
								</li>
								<li>
									<a
										href="/"
										aria-label="Product pricing"
										title="Product pricing"
										class="
											tracking-wide
											text-gray-800
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>Pricing</a
									>
								</li>
								<li>
									<a
										href="/"
										aria-label="About us"
										title="About us"
										class="
											tracking-wide
											text-gray-800
											transition-colors
											duration-200
											font-semi-bold
											hover:text-teal-accent-400
										"
										>About us</a
									>
								</li>
							</ul>
							<ul class="flex items-center hidden space-x-8 lg:flex">
								<li>
									<button
										class="
											hidden
											rounded
											flbtn
											btn-pink
											hover:bg-white
											hover:text-black
										"
										@click="$router.push('/register')"
										type="button"
									>
										Pre Order
									</button>
								</li>
							</ul>
						
							<div class="lg:hidden">
								<button
									aria-label="Open Menu"
									title="Open Menu"
									class="
										p-2
										-mr-1
										transition
										duration-200
										rounded
										focus:outline-none
										focus:shadow-outline
									"
								>
									<svg class="w-5 text-gray-600" viewBox="0 0 24 24">
										<path
											fill="currentColor"
											d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
										></path>
										<path
											fill="currentColor"
											d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
										></path>
										<path
											fill="currentColor"
											d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
										></path>
									</svg>
								</button>
							</div>
						</div> -->
					</div>
				</div>

				<div
					class="
						px-4
						py-16
						mx-auto
						sm:max-w-xl
						md:max-w-full
						lg:max-w-screen-xl
						md:px-24
						lg:px-8
						lg:py-20
					"
				>
					<!-- <div
						class="flex flex-col items-center justify-between w-full mb-10 lg:flex-row"
					> -->
					<p class="pb-5 text-4xl"><strong>Terms and Conditions</strong></p>
					<br />
					<ul>
						<li><strong> 1. Scope of Application</strong></li>
						<br />
						<li>
							<strong> 1.1</strong>The following General Terms and Conditions is
							an integral part of every contract concluded by means of the
							"FunnyFlirts" smartphone app or the website
							<a href="https://FunnyFlirts.com">https://FunnyFlirts.com</a
							>(hereinafter referred to as "Service") between A.M.E International Media Ltd. , gf 168 214 baker street
enfield
47910 london
united kingdom en1 3jt (hereinafter referred to as
							"Operator") and the User.
						</li>
						<br />
						<li>
							<strong> 1.2</strong>The Operator provides its services
							exclusively on the basis of these General Terms and Conditions.
						</li>
						<br />
						<li>
							<strong> 1.3</strong>Persons who have not yet reached the age of
							18, or who are in guardianship or a care relationship in whole or
							in part and thus do not have full legal capacity may not use the
							Service. The User confirms and accepts these regulations with
							his/her registration. In case of violation or contravention of the
							regulations, the respective user account will be deleted without
							consultation and regardless of any remaining credit balances. The
							same regulation applies in the case of the use of the Service for
							commercial purposes. The user account will also be deleted by the
							Operator in such cases.
						</li>
						<br />
						<li><strong> 2. Conclusion of the Contract</strong></li>
					</ul>
					<br />
					<p>
						The contract between the Operator and the User shall be concluded by
						the creation of a user account by means of the free registration of
						the User on the website or by means of the respective mobile apps.
					</p>
					<ul>
						<br />
						<li><strong> 3. Subject Matter of the Contract</strong></li>
						<li>
							<strong> 3.1</strong>The Service provided by the Operator offers
							the User the opportunity to communicate interactively and engage
							in online dating with other users. To this end, the Operator
							offers users access to its database and the user profiles that are
							stored in it. Users can view these profiles, add their own profile
							to the database, and contact other users. Contact and
							communication between users shall be conducted by means of the
							Service.
						</li>
						<br />
						<li>
							<strong> 3.2</strong>The Operator shall only be responsible for
							the attempt to establish contact with other users and does not
							guarantee that contact will actually be established.
						</li>
						<br />
						<li>
							<strong> 3.3</strong>The use of the basic Service is free of
							charge; only the individual services which are additionally
							offered by the Operator are subject to a charge. A detailed
							description of these services is provided within the Service
							itself.
						</li>
						<br />
						<li>
							<strong> 3.4</strong>Should the User wish to use a chargeable
							service, he/she can purchase Flirts for this purpose from the
							Operator, which are credited to his/her user account. Payment
							shall be made by the type and means of payment described in each
							case.
						</li>
						<br />
						<li>
							<strong> 3.5</strong>Should the User wish to use a chargeable
							service and pay by means of flirts, the Operator will inform the
							User how many Flirts will be charged for the use of the respective
							service.
						</li>
						<br />
						<li>
							<strong> 3.6</strong>In order to guarantee users an optimal
							service experience and ensure availability of the Service at all
							times, the Operator employs professional entertainers and profile
							attendants to look after and entertain users. Such persons are not
							identified in a special manner by the system. Users can only send
							messages to and conduct dialogues with such individuals within the
							Service; they cannot meet them in person.
						</li>
						<br />
						<li>
							<strong> 3.7</strong> FunnyFlirts grants each user a one-time
							welcome bonus of 50 Flirts. The Operator reserves the right to
							deactivate the accounts of users that have created multiple user
							accounts in order to achieve unauthorized multiple use of the
							welcome bonus.
						</li>
						<br />
						<li><strong> 4. Contractual Obligations</strong></li>
						<br />
						<li>
							<strong> 4.1</strong>The Operator shall make the Service available
							to the User and grant a simple right of use that is unrestricted
							in terms of location or time. However, this right of use is
							limited to the use of the content provided for private purposes.
						</li>
						<br />
						<li>
							<strong> 4.2</strong>Upon registration, the User shall provide the
							Operator with the requested information both completely and
							truthfully. The Operator must be notified immediately of any
							changes to such information.
						</li>
						<br />
						<li>
							<strong> 4.3</strong>The content provided by the User for
							publication through the Service and the manner in which it is
							presented must comply with the applicable legal regulations, and
							must not infringe the rights of third parties; in particular, the
							User may not publish any defamatory, offensive, racist or
							otherwise illegal content.
						</li>
						<br />
						<li>
							<strong> 4.4</strong>The User is not permitted to use either the
							Service or the data of other users for commercial purposes.
						</li>
						<br />
						<li>
							<strong> 4.5</strong>The User shall treat messages and the
							personal data of other users that he/she receives in connection
							with using the Service confidentially, and not make such messages
							or data accessible to third parties without the consent of the
							user concerned.
						</li>
						<br />
						<li>
							<strong> 4.6</strong>Should the User violate his/her contractual
							obligations under § 4 para. 3 to para. 5 of these General Terms
							and Conditions, the Operator shall be entitled to remove the
							User's content from the Service, block his/her access, and — in
							the event of gross violations — be entitled to terminate the
							contractual relationship extraordinarily with immediate effect.
							The User shall retain the right to prove that blocking his/her
							access was unjustified. In the event that the User has chosen to
							avail of a chargeable service at the time when access is blocked
							or the contract is terminated, the Operator's right to
							remuneration shall remain unaffected.
						</li>
						<br />
						<li>
							<strong> 4.7</strong>The User shall bear responsibility for all
							the content he/she provides and publishes through his/her account,
							unless he/she is not accountable for this content.
						</li>
						<br />
						<li>
							<strong> 4.8</strong>The User shall ensure that he/she has the
							appropriate rights to all content (e.g. texts, images, etc.) that
							he/she or the Operator publishes for him/her through the Service.
						</li>
						<br />
						<li>
							<strong> 4.9</strong>Should third parties assert claims against
							the Operator in connection with the publication of content by the
							User through the Service, or in connection with the illegal use of
							the Service by the User for which the User is accountable, the
							User undertakes to release the Operator completely and immediately
							from such claims.
						</li>
						<br />
						<li>
							<strong> 4.10</strong>The User shall have no legal claim to
							updates or upgrades of the Service. The Operator will nevertheless
							make every effort to provide regular updates and upgrades.
						</li>
						<br />
						<li><strong> 5. Further Development of the Service</strong></li>
						<br />
						<li>
							<strong> 5.1</strong>The Operator reserves the right to make
							further developments and improvements to the Service (e.g. by
							adopting newer or different technologies, systems, procedures or
							standards) in the interest of technical progress and performance
							optimization after conclusion of the contract. The Operator shall
							inform the User in good time in the event of significant changes
							to the Service. Should the User suffer significant disadvantages
							as a result of changes to the Service, he/she shall have the right
							to terminate the contract exceptionally on the date of such
							changes. The User shall terminate the contract within two weeks of
							receiving notification of the changes to the Service.
						</li>
						<br />
						<li>
							<strong> 5.2</strong>When providing new versions of the Service,
							the Operator shall also grant the User the rights listed in § 4 of
							these General Terms and Conditions for the respective new version.
						</li>
						<br />
						<li>
							<strong> 5.3</strong>MAZU™ powerd by gf 168 214 baker street
enfield
47910 london
united kingdom en1 3jt. 2021<br/>
							Author and Concept: Eric Rönnau, Development: Sumit, Nilshan 
						</li>
						<br/>
						<li><strong> 6. Liability of the Operator</strong></li>
						<br />
						<li>
							<strong> 6.1</strong>The Operator assumes no liability for
							assuring that contact between users actually occurs. Liability is
							also excluded in the event that no contact occurs during the term
							of the contract; this also applies to the use of services for
							which a fee is charged.
						</li>
						<br />
						<li>
							<strong> 6.2</strong>In principle, the statutory right to assert
							claims for defective service applies, unless otherwise provided
							for in § 6 para. 3 to para. 5 of these General Terms and
							Conditions.
						</li>
						<br />
						<li>
							<strong> 6.3</strong>The Operator is only liable for damages other
							than those resulting from injury to life, body or health if the
							damages are due to deliberate or grossly negligent activities or
							the culpable breach of a fundamental contractual obligation by the
							Operator, its employees or its vicarious agents. This also applies
							to damages resulting from the violation of obligations during
							contractual negotiations and from the performance of unauthorized
							activities. Any further liability for damages is excluded.
						</li>
						<br />
						<li>
							<strong> 6.4</strong>Except in the case of intentional or grossly
							negligent conduct, the violation of a cardinal obligation, or
							injury to life, body and health by the Operator, its employees or
							its vicarious agents, liability is limited to the damages
							typically foreseeable at the time of conclusion of the contract,
							and otherwise to the average amount of damages typical to the
							contract. This also applies to indirect damages, in particular
							loss of profit.
						</li>
						<br />
						<li>
							<strong>
								6.5 The provisions of the Product Liability Act remain
								unaffected.</strong
							>
						</li>
						<br />
						<li><strong> 7. Contract Termination</strong></li>
						<br />
						<li>
							<strong> 7.1</strong>The contract between the Operator and the
							User shall be concluded for an indefinite period.
						</li>
						<br />
						<li>
							<strong> 7.2</strong>The contract may be terminated by either
							party at any time and without notice. In the event that the User
							has chosen to avail of a chargeable service at the time of the
							notice of termination, the termination shall become effective at
							the time when the chargeable service expires.
						</li>
						<br />
						<li>
							<strong> 7.3</strong>This shall not affect the right to
							extraordinary termination for good cause.
						</li>
						<br />
						<li><strong> 8. Refund / Revocation</strong></li>
					</ul>
					<br />
					<p>
						The user acknowledges that the operator is under no obligation to
						reimburse any virtual currency that has already been used. In this
						case, revocation is also excluded. Furthermore, there is no
						entitlement to money or other compensation for unused remaining
						credit. This applies in particular in the event of a violation of §1
						point 3 or §3 point 7 in these terms and conditions, or if the
						account is deleted/closed by the user himself. However, the operator
						will examine every application on a case-by-case basis and has the
						right to make refunds or partial refunds. These reimbursements are
						only made out of goodwill and without recognition of a legal
						obligation.
					</p>
					<br />
					<ul clas>
						<li><strong> 9. Bonus-Flirt Game Regulations</strong></li>
						<br />
						<li><strong> 11. House Rules</strong></li>
						<br />
					</ul>
					<p>
						By using the Service, the User agrees to abide by the so-called
						“house rules” of FunnyFlirts, which dictate proper and acceptable
						behavior on and off the Service.
					</p>
					<p>The User agrees to refrain from:</p>
					<ul>
						<li>
							using the Service for any purpose that is illegal or prohibited by
							this Agreement or any applicable law.
						</li>
						<li>using the Service for any harmful or nefarious purpose.</li>
						<li>
							using the Service to damage FunnyFlirts or other services provided
							by the Operator.
						</li>
						<li>
							Spamming, soliciting money from, or defraud any other Users.
						</li>
						<li>
							Impersonating any person or entity or post any images of another
							person without his or her explicit permission and consent.
						</li>
						<li>
							Bullying, “stalking”, intimidate, assault, harass, mistreat, or
							defame any person.
						</li>
						<li>
							Posting any Content that violates or infringes anyone’s rights,
							including rights of publicity, privacy, copyright, trademark, or
							other intellectual property or contract right.
						</li>
						<li>
							Posting any Content that is hate speech, threatening, sexually
							explicit, or pornographic; incites violence, or contains nudity or
							graphic or gratuitous violence.
						</li>
						<li>
							Posting any Content that promotes racism, bigotry, hatred, or
							physical harm of any kind against any group or individual.
						</li>
						<li>
							Soliciting passwords for any purpose, or personal identifying
							information for commercial or unlawful purposes from other users,
							or disseminate another person’s personal information without his
							or her explicit permission and consent.
						</li>
						<li>
							Using another user’s account, share an account with another user,
							or maintain more than one account.
						</li>
						<li>
							Creating another account if an account of the User has already
							been terminated by the Operator—unless the User has obtained the
							explicit permission of the Operator.
						</li>
					</ul>
					<br />
					<p>
						The Operator reserves the right to investigate and/ or terminate the
						Service for a given User without a refund of any purchases if the
						User is in violation this Agreement has misused the Service or
						behaved in a way that the Operator regards as inappropriate or
						unlawful, or in breach of these “House Rules”; including actions or
						communications that occur on or off the Service.
					</p>
				</div>
				<!-- </div> --><page-footer />
			</div>
		</section>
		
	</div>
</template>

<script>
import PageFooter from '@/components/purple/pageFooter.vue'
import { getCommunityMeta } from '../../utils/helpers';
export default {
	components: { PageFooter },

    data () {
        return{
            getCommunityMeta,


        }

    }
}

</script>PageFooter