<template>
  <div class="flex h-screen antialiased text-gray-800 lg:mx-48 xl:mx-48">
    <notification-bar />
    <div class="flex flex-row h-full w-full overflow-x-hidden">
      <div
        class="
          lg:flex
          xl:flex
          flex-col flex-auto
          xl:h-3/4
          lg:h-3/4 lg:mt-28
          xl:mt-28
          h-full
        "
      >
        <div
          class="
            flex flex-col flex-auto flex-shrink-0
            bg-gray-100
            dark:bg-gray-700
            h-full
          "
        >
          <nav class="bg-white dark:bg-gray-800 lg:h-20 xl:h-20 items-start">
            <div class="flex items-stretch ...">
              <button
                class="
                  btn btn-sm
                  my-3
                  mx-3
                  rounded-none
                  dark:text-white
                  justify-start
                "
                @click="
                  $router.push(
                    $route.query.dm
                      ? `/chat-list?dm=${$route.params.chat}`
                      : '/chat-list'
                  )
                "
              >
                Backs
              </button>
            </div>
          </nav>
          <!-- <div
            ref="messages"
            class="flex-auto overflow-y-auto p-5 space-y-4 h-screen"
            style="
              background-image: url(https://static.intercomassets.com/ember/assets/images/messenger-backgrounds/background-1-99a36524645be823aabcd0e673cb47f8.png);
            "
          >
            <d-chat-message
              v-for="({ id, body, sender, attachment_url ,message_type , paid  }, i) in allMessages"
              :key="i"
              :avatar="getURL(sender.profilePic)"
              :messages="[{ id, body, sender, attachment_url ,message_type , paid  }]"
              :sent="sender.username == $store.state.core.user.username"
            />
          </div> -->
          <!-- <message-field
            v-if="
              $store.state.core.balance > 0 || $store.state.core.user.has_hpass
            "
            :loading="loadingSend"
            @photoMessageSent="photoMessageSent"
            @messageSent="messageSent"
            ref="messageField"
          /> -->
          <div class="flex flex-col flex-auto flex-shrink-0  bg-white py-10     dark:bg-gray-700 h-full">

              <div ref="messages" class="flex-auto overflow-y-auto p-0  space-y-4 mt-0" style=" background-size: cover;
                 background-image: url(https://images.flirttool.com/chat.jpg);
                      ">
                <center v-if="loadingConvo">
                  <div class="mt-14 lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </center>

                <d-chat-message v-else
                  v-for="({ id, body, sender, attachment_url, message_type, paid }, i) in allMessages" :key="i"
                  :avatar="getURL(sender.profilePic)" :messages="[{ id, body, attachment_url, message_type, paid }]"
                  :sent="sender.username == $store.state.core.user.username" @showFlirtOn="showing = true"
                  @refetchChat="refetchChat" />
              </div>
              <message-field :loading="loadingSend"
                v-if="$store.state.core.balance > 0 || $store.state.core.user.has_hpass" :disabled="disabled"
                ref="messageField" @messageSent="messageSent" @photoMessageSent="photoMessageSent" />

              <message-field v-else-if="
                $store.state.core.user.package_activity &&
                $store.state.core.user.package_activity.active
              " :loading="loadingSend" :disabled="disabled" ref="messageFieldx" @messageSent="messageSent"
                @photoMessageSent="photoMessageSent" />
              <div v-else-if="!$store.state.core.user.package_activity"
                class="items-center h-24 mb-3 dark:bg-gray-600 bg-pink-50 w-full md:mb-4">
                <p class="text-center pt-3 dark:text-gray-200">
                  You don't have enough credits
                </p>
                <router-link to="/flirts" class="btn dark:bg-pink-200">Get Flirts</router-link>
              </div>
              <div v-else-if="!$store.state.core.user.package_activity.active"
                class="items-center h-24 mb-3 dark:bg-gray-600 bg-pink-50 w-full md:mb-4">
                <p class="text-center pt-3 dark:text-gray-200">
                  You don't have enough credits
                </p>
                <router-link to="/flirts" class="btn dark:bg-pink-200">Get Flirts</router-link>
              </div>
            </div>
          <!-- <div
            v-else
            class="
              items-center
              h-24
              mb-3
              dark:bg-gray-600
              bg-pink-50
              w-full
              md:mb-4
            "
          >
            <p class="text-center pt-3 dark:text-gray-200">
              You don't have enough credits
            </p>
            <router-link to="/flirts" class="btn dark:bg-pink-200 mb-5"
              >Get Flirts</router-link
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_MESSAGES, MESSAGE_LISTEN, SEND_MESSAGE } from "../../queries/chat";
import DChatMessage from "../../components/ChatMessage.vue";
import MessageField from "../../components/chat/messageField.vue";
import { getURL } from "../../utils/helpers";
import { DM_TARGET_INFO } from "../../queries/auth";
import NotificationBar from "../../components/NotificationBar.vue";
export default {
  data: () => ({
    renderedMessages: [],
    allMessages: [],
    loadingSend: false,
    selectedConvo: null,
    convoStatus: false,
    convoSelected: false,
    newTarget: null,
    disabled: false,
    bgColor: "#dc4b78",
    position: "bottom-right",
    fabActions: [
      {
        name: "toMatches",
        icon: "favorite",
      },
    ],
  }),
  components: {
    MessageField,
    DChatMessage,
    NotificationBar,
  },
  // created() {
  //   console.log(this.$route.query.dm);
  //   if (!this.$route.query.dm) {
  //     this.fetchAllMessages();
  //   } else {
  //     console.log("dawg");
  //     this.fetchTargetInfo();
  //   }
  //   // this.fetchConvo()
  //   window.addEventListener("resize", this.onResize);
  // },
  created() {
    if (this.$store.getters.showFlirton) this.showing = true
    this.$apollo.queries.allConversations.refetch();
    if (!this.$route.query.dm) {
      this.$apollo.subscriptions.messageAdded.refresh();
    }
    this.$apollo.queries.convoStatus.refetch();
    window.addEventListener("resize", this.onResize);
  },
  apollo: {
    // $subscribe: {
    //   messageAdded: {
    //     query: MESSAGE_LISTEN,
    //     result({ data }) {
    //       console.log(data, "NEW MESSAGE");
    //       const message = data.messageAdded.mes;
    //       if (!this.$route.query.dm) {
    //         if (
    //           message.sender.username == this.selectedConvo.m_one.username ||
    //           message.sender.username == this.$store.state.core.user.username
    //         ) {
    //           this.allMessages.push(data.messageAdded.mes);
    //           const container = this.$refs.messages;
    //           setTimeout(() => {
    //             container.scrollTop = container.scrollHeight;
    //           }, 200);
    //         } else {
    //           alert("this is notification");
    //         }
    //       } else {
    //         if (
    //           message.sender.username == this.newTarget.username ||
    //           message.sender.username === this.$store.state.core.user.username
    //         ) {
    //           this.allMessages.push(data.messageAdded.mes);
    //           const container = this.$refs.messages;
    //           setTimeout(() => {
    //             container.scrollTop = container.scrollHeight;
    //           }, 200);
    //         } else {
    //           alert("this is notification");
    //         }
    //       }
    //     },
    //   },
    // },
    $subscribe: {
      messageAdded: {
        query: MESSAGE_LISTEN,
        variables() {
          return {};
        },
        result({ data }) {
          const message = data.messageAdded.mes;
          console.log(this.selectedConvo, "LL");

          if (this.$route.query.dm && !this.selectedConvo) {
            console.log("inside DM", message.sender.username);
            console.log(this.newTarget.username);
            if (
              message.sender.username == this.newTarget.username ||
              message.sender.username === this.$store.state.core.user.username
            ) {
              this.allMessages.push(message);
              const container = this.$refs.messages;
              setTimeout(() => {
                container.scrollTop = container.scrollHeight;
              }, 200);
              // } else {
              // 	this.allMessages.push(message)
              // 	console.warn(message,"MSG")
              // alert(message.body)
              console.log("whoaaaaaaaaaaaaaaaaaaaaaaaaaaa IS DM");
              console.log(message.sender);
              console.log(this.newTarget);
              console.log("whoaaaaaaaaaaaaaaaaaaaaaaaaaaa");
            }
          } else if (this.selectedConvo) {
            if (
              message.sender.username === this.selectedConvo.m_one.username ||
              message.sender.username === this.$store.state.core.user.username
            ) {
              this.allMessages.push(message);
              const container = this.$refs.messages;
              setTimeout(() => {
                container.scrollTop = container.scrollHeight;
              }, 200);
              // } else {
              console.log("whoaaaaaaaaaaaaaaaaaaaaaaaaaaa NOT DM");
              console.log(message.sender);
              console.log(this.selectedConvo.m_one);
              console.log("whoaaaaaaaaaaaaaaaaaaaaaaaaaaa");
              // alert(message.body)
            }
          }
        },
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getURL,
    fetchTargetInfo() {
      this.$apollo
        .query({
          query: DM_TARGET_INFO,
          variables: {
            dtarget: this.$route.params.chat,
          },
        })
        .then(({ data: { dmTargetInfo } }) => {
          this.newTarget = dmTargetInfo;
          this.disabled = false;
          console.log(dmTargetInfo, "Targe Info");
        });
    },
    photoMessageSent(message, file) {
      this.loadingSend = true;
      this.$apollo
        .mutate({
          mutation: SEND_MESSAGE,
          variables: {
            input: {
              body: message,
              user: this.selectedConvo
                ? this.selectedConvo.m_one.username
                : this.newTarget.username,
              attachment: "",
            },
            file: file.target.files[0],
          },
        })
        .then(({ data }) => {
          this.loadingSend = false;
          if (data) {
            this.$refs.messageFieldx
              ? this.$refs.messageFieldx.emptyMessageField()
              : this.$refs.messageField.emptyMessageField();
            this.$store.commit("setBalance", data.sendMessage.flirts);
            if (this.$store.getters.showFlirton) this.showing = true
          }
        })
        .catch((e) => {
          this.loadingSend = false;
          if (e.message == "GraphQL error: Spam detected") {
            this.$notify(
              {
                group: "top",
                title: "chat",
                text: "please give them a minute",
                type: "info", // info, warning
              },
              5000
            );
          }
        });
      console.log(message, file);
    },
    // messageSent(message) {
    //   // console.log(this.newTarget.yser, "SEL")
    //   let targetedUser = "";
    //   // const targetedUser = this.selectedConvo ? this.selectedConvo.m_one.username : this.newTarget.username
    //   console.log(this.selectedConvo, "SELECTED");
    //   if (this.selectedConvo) {
    //     targetedUser = this.selectedConvo.m_one.username;
    //   } else {
    //     console.log("ww", this.newTarget.target.username);
    //     targetedUser = this.newTarget.target.username;
    //   }
    //   this.$apollo
    //     .mutate({
    //       mutation: SEND_MESSAGE,
    //       variables: {
    //         input: {
    //           body: message,
    //           user: targetedUser,
    //           attachment: "",
    //         },
    //       },
    //     })
    //     .then(({ data }) => {
    //       if (data) {
    //         this.$refs.messageField.emptyMessageField();
    //          this.$store.commit("setBalance", data.sendMessage.flirts);
    //       }
    //     })
    //     .catch((e) => {
    //       // handle spam mesage response here
    //       if (e.message == "GraphQL error: Spam detected") {
    //         this.$notify(
    //           {
    //             group: "top",
    //             title: "chat",
    //             text: "please give them a minute",
    //             type: "info", // info, warning
    //           },
    //           5000
    //         );
    //       }
    //     });
    // },
    messageSent(message) {
      if (message.body.length >= 1 || message.type == 'g') {
        console.log(message)
        this.$apollo
          .mutate({
            mutation: SEND_MESSAGE,
            variables: {
              input: {
                body: message.body,
                user: this.selectedConvo
                  ? this.selectedConvo.m_one.username
                  : this.newTarget.username,
                attachment: message.attachment,
                message_type: message.type,
                attachment_url: message.attachment_url,
                ...(message.type == 'g' && { gift_id: message.gift_id }),
              },
            },
          })
          .then(({ data }) => {
            if (data) {
              this.$refs.messageFieldx
                ? this.$refs.messageFieldx.emptyMessageField()
                : this.$refs.messageField.emptyMessageField();
              this.$store.commit("setBalance", data.sendMessage.flirts);
              if (this.$store.getters.showFlirton) this.showing = true
            }
          })
          .catch((e) => {
            if (e.message == "GraphQL error: Spam detected") {
              this.$notify(
                {
                  group: "top",
                  title: "chat",
                  text: "please give them a minute",
                  type: "info", // info, warning
                },
                5000
              );
            } else if (e.message == "GraphQL error: You don't have enough flirts") {
              this.giftshowing = true
            }
          });
      } else {
        alert("can't send empty message");
      }
    },
    fetchAllMessages() {
      this.$apollo
        .query({
          query: GET_MESSAGES,
          variables: {
            conv: parseInt(this.$route.params.chat),
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.allMessages = data.allMessages.messages;
          let messages = data.allMessages.messages;
          this.renderedMessages = messages;
          const container = this.$refs.messages;
          this.selectedConvo = data.allMessages.convo;
          // console.log(container.scrollHeight)
          setTimeout(() => {
            container.scrollTop = container.scrollHeight;
          }, 200);
        });
    },
    // Handle resize
    onResize() {
      if (window.innerWidth >= 1200) {
        console.log("Extra Large", window.innerWidth);
        this.$router.push("/chat");
      } else if (window.innerWidth >= 992) {
        console.log("Large", window.innerWidth);
        this.$router.push("/chat");
      } else if (window.innerWidth >= 768) {
        this.$router.push("/chat");
        console.log("Medium", window.innerWidth);
      } else if (window.innerWidth >= 576) {
        console.log("Small", window.innerWidth);
      } else if (window.innerWidth < 576) {
        console.log("X-Small");
      }
    },
  },
};
</script>